<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">分润明细</span>
        </div>

        <div style="display: flex; margin: 10px 0;font-size: 12px;">
            <div style="width: 460px;margin-right: 20px;">
              <span>起止日期：</span>
              <el-date-picker
                v-model="query_condition.query_time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini">
              </el-date-picker>
            </div>
            <div style="display: flex; line-height: 30px; margin-right: 20px;">
                <div>类型：</div>
                    <el-select v-model="query_condition.type" placeholder="请选择" style="width: 120px" size="mini">
                    <el-option
                        v-for="item in type_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>                
            </div>
            <div style="display: flex; line-height: 30px; margin-right: 20px;">
              <span style="width: 61px;">服务商：</span>
              <el-input v-model="query_condition.name" placeholder="请输入通道名称" size="mini"></el-input>
            </div>               
            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
            <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>

        <div class="table_list">
          <el-table
            :data="record_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
            label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="发生时间">
            </el-table-column>
            <el-table-column
              prop="name"
              label="服务商名称">
            </el-table-column>
            <el-table-column
              prop="type"
              label="发生类型">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="发生金额">
            </el-table-column>
            <el-table-column
              prop="service_recharge"
              label="服务商分润">
            </el-table-column>
            
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>     
  </div>
</template>

<script>
import { share_recorde_api } from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "sharerecord",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      type_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: '代付',
          label: '代付'
        },
        {
          value: '代收',
          label: '代收'
        },
        {
          value: '冲正',
          label: '冲正'
        },
        ],
      query_condition: {
        query_time: [], 
        state: "",
        _name: '',
        type: ''
      },        
      record_infos: [],
    }
  },
  created() {
    this.query()
  },
  methods:{
  currentpage(val){
      this.page.page = val
      this.query()
    },
  condition_query(){
    this.page.page = 1
    this.query()
  },
  structure_data(){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      return {
        state: this.query_condition.state,
        start_time: start_time,
        end_time: end_time,
        _name: this.query_condition.name,
        type: this.query_condition.type,
        page: this.page.page,
        page_size: this.page.page_size
      }
    },
  query(){
      this.startLoading("查询中。。")      
      share_recorde_api(this.structure_data()).then((res) => {       
          this.endLoading() 
          if (res.code === 200) {
            this.record_infos = res.data.datas
            this.record_infos.forEach(item => {
            item.create_time = this.$globalJs.picker_time(item.create_time, 1)
            if (item.amount > 0) item.amount = '+' + item.amount
            })
            this.page.total = res.data.total
          }else{
            this.$message.error(res.message)
          }
    })
    },   
  reset_condition(){
      this.query_condition = {
        query_time: [], 
        state: "",
        _name: '',
        type: ''
      }
      this.page.page = 1
      this.query()
    },
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>

