<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">支付方式</span>
          <div style="float: right">
            <el-button @click="add_channel" type="primary" round>新增支付方式</el-button>
          </div>          
        </div>

        <div class="table_list">
          <el-table
            :data="channel_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
            label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              prop="name"
              label="支付名称"
              width="120">
            </el-table-column>
            <el-table-column
              prop="method"
              label="方法"
              width="120">
            </el-table-column>
            <el-table-column
              prop="api_add"
              label="接口地址">
            </el-table-column>
            <el-table-column
              label="操作"
              width="160">
              <template slot-scope="scope">
                <el-button size="mini" @click="edit(scope.row)">修改</el-button>
                <el-button size="mini" @click="del_method(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

      <el-dialog
        title="增加支付方式"
        :close-on-click-modal="false"
        :visible.sync="add_channel_dialog"
        width="25%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_info"
        ref="add_channel"
        label-width="70px"
        class="form_regist"
      >
        <el-col :span="20">
          <el-form-item label="通道名称">
            <el-input
              v-model="edit_info.name"
              placeholder="请输入通道名称"            
              style="width: 100%">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="20">
          <el-form-item label="调用方法">
            <el-input
              v-model="edit_info.method"
              placeholder="请输入调用方法"            
              style="width: 100%">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="20">
          <el-form-item label="接口地址">
            <el-input
              v-model="edit_info.api_add"
              placeholder="请输入接口地址"            
              style="width: 100%">
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog">取 消</el-button>
        <el-button type="primary" @click="commit_add_channel"
          >确 定</el-button
        >
      </span>
    </el-dialog>    

     <el-dialog
        title="修改支付方式"
        :close-on-click-modal="false"
        :visible.sync="edit_channel_dialog"
        width="25%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_info"
        ref="add_channel"
        label-width="70px"
        class="form_regist"
      >
        <el-col :span="20">
          <el-form-item label="通道名称">
            {{this.edit_info.name}}
          </el-form-item>
        </el-col>

        <el-col :span="20">
          <el-form-item label="调用方法">
            <el-input
              v-model="edit_info.method"
              placeholder="请输入调用方法"            
              style="width: 100%">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="20">
          <el-form-item label="接口地址">
            <el-input
              v-model="edit_info.api_add"
              placeholder="请输入接口地址"            
              style="width: 100%">
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog">取 消</el-button>
        <el-button type="primary" @click="commit_edit_channel"
          >确 定</el-button
        >
      </span>
    </el-dialog>   
    

  </div>
</template>

<script>
import { pay_method_api, add_method_api, edit_method_api, del_method_api } from "../network/api/channel";
import paging from "../components/pagenation";

export default {  
  name: "PayMethod",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      channel_infos: [],      
      edit_info: {},
      add_channel_dialog: false,
      edit_channel_dialog: false,
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    query(){
      this.startLoading("查询中。。")      
      const data = {
        page: this.page.page,
        page_size: this.page.page_size,
        type: 'paging'
      }
      pay_method_api(data).then((res) => {       
          this.endLoading() 
          if (res.code === 200) {
            this.channel_infos = res.data.datas
            this.page.total = res.data.total
          }else{
            this.$message.error(res.message)
          }
    })
    },   
    add_channel(){
      this.add_channel_dialog = true
    },
    commit_add_channel(){
      if (!this.edit_info.name || !this.edit_info.method || !this.edit_info.api_add){
        this.$message.error('信息必须填写完整')
        return
      }
      add_method_api(this.edit_info).then((res)=>{
        if (res.code === 200) {
          this.$message.success('添加成功')
          this.query()
          this.close_dialog()
        }else this.$message.error('添加失败')
      })
    },
    edit(row){
      this.edit_info.name = row.name
      this.$set(this.edit_info, 'method', row.method)
      this.$set(this.edit_info, 'api_add', row.api_add)
      this.edit_channel_dialog = true
      this.row = row
    },
    commit_edit_channel(){
      if (!this.edit_info.name || !this.edit_info.method || !this.edit_info.api_add){
        this.$message.error('信息必须填写')
        return
      }
      const l_key = ['name', 'method', 'api_add']
      let key = true
      for (let i=0; i<l_key.length; i++){
        console.log(l_key[i], l_key.length, this.row[l_key[i]] != this.edit_info[l_key[i]])
        if (this.row[l_key[i]] != this.edit_info[l_key[i]]) {          
          key = false
          break
          }
      }
      if (key) {
        this.$message.error('无修改')
        return
      }      
      edit_method_api(this.edit_info).then((res)=>{
        if (res.code === 200) {
          this.$message.success('添加成功')
          this.row.method = this.edit_info.method
          this.row.api_add = this.edit_info.api_add
          this.close_dialog()
        }else this.$message.error('添加失败')
      })
    },
    del_method(row){
      const data = {name: row.name}
      del_method_api(data).then((res)=>{
        if (res.code === 200){
          this.query()
        }else this.$message.error('删除失败')
      })

    },
    close_dialog(){
      this.edit_info = {}
      this.add_channel_dialog = false
      this.edit_channel_dialog = false
    },
},
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>

