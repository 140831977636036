import request from "../request";
export function reg_channel_api(data) {
    return request({
      url: '/channel/reg_channel',
      method:'post',
      data: data,
    })
  }  
export function channel_query_api(data) {
  return request({
    url: '/channel/channel_query',
    method:'get',
    params: data
  })
}
export function channel_manage_api(data) {
    return request({
      url: '/channel/channel_manage',
      method:'post',
      data: data,
    })
  }  
export function join_merchant_api(data) {
  return request({
    url: '/channel/join_merchant',
    method:'get',
    params: data
  })
}

export function pay_method_api(data) {
  return request({
    url: '/pay_method/',
    method:'get',
    params: data
  })
}

export function add_method_api(data) {
  return request({
    url: '/pay_method/add_method',
    method:'post',
    data: data
  })
}
export function edit_method_api(data) {
  return request({
    url: '/pay_method/edit_method',
    method:'post',
    data: data
  })
}
export function del_method_api(data) {
  return request({
    url: '/pay_method/del_method',
    method:'post',
    data: data
  })
}