<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">收款查询</span>
        </div>

        <div style="display: flex; margin: 10px 0; font-size: 12px;">
            <div style="width: 460px; margin-right: 20px;">
              <span>起止日期：</span>
              <el-date-picker
                v-model="query_condition.query_time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini">
              </el-date-picker>
            </div>
            <div style="display: flex; line-height: 30px; margin-right: 20px;">
              <span style="width: 98px;">商户订单号：</span>
              <el-input v-model="query_condition.merchant_order_no" placeholder="请输入商户订单号" size="mini"></el-input>
            </div>                        
            <div style="display: flex; line-height: 30px; margin-right: 20px;">
              <span style="width: 79px;">商户名称：</span>
              <el-input v-model="query_condition._merchant" placeholder="请输入商户名称" size="mini"></el-input>
            </div>

            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
            <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
            <div style="margin-left: 10px;">
            <el-popover
              placement="right"
              width="220"
              trigger="click">
              <div>
                <p>金额：{{this.all_info.amount}}</p>
                <p>手续费：{{this.all_info.charge}}</p>
                <p>笔数：{{this.all_info.times}}</p>
                <p>结算：{{this.all_info.settlement}}</p>
              </div>
              <el-button slot="reference" type="primary" @click="get_sum" size="mini" style="height: 30px !important">统计</el-button>
            </el-popover>
          </div>
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>


        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }"
            >
            <el-table-column
              prop="create_time"
              label="发起时间"
              width="132">
            </el-table-column>
            <el-table-column
              prop="processing_time"
              label="处理时间"
              width="132">
            </el-table-column>
            <el-table-column
              prop="merchant"
              label="商户名称"
              width="120">
            </el-table-column>            
            <el-table-column
              prop="amount"
              label="付款金额"
              width="88">
            </el-table-column>
            <el-table-column
              prop="realamount"
              label="结算金额"
              width="88">
            </el-table-column>
            <el-table-column
              prop="channel_charge"
              label="通道手续费"
              width="88">
            </el-table-column>            
            <el-table-column
              prop="service_charge"
              label="服务商手续费"
              width="92">
            </el-table-column>
            <el-table-column
              prop="merchant_order_no"
              label="商户订单号">
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

  </div>
</template>

<script>
import { service_collection_api, service_sum_api } from "../network/api/service";
import paging from "../components/pagenation";

export default {  
  name: "ServiceCollection",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      query_condition: {
        query_time: [], 
        _merchant: '',
        merchant_order_no: '',
      },        
      collection_infos: [],
      all_info: {
        amount: 0,
        times: 0,
        charge: 0,
        settlement: 0
      },
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(type=1){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      const data = {
        start_time: start_time, 
        end_time: end_time,
        _merchant:  this.query_condition._merchant,
        merchant_order_no:  this.query_condition.merchant_order_no,
      }
      if (type === 1){
        data.page = this.page.page
        data.page_size = this.page.page_size
      }
      return data
    },
    query(){        
        this.startLoading("查询中。。")      
        service_collection_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1)
                item.processing_time = this.$globalJs.picker_time(item.processing_time, 1)
                item.realamount = item.amount - item.channel_charge
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    reset_condition(){
      this.query_condition = {
        query_time: [], 
        _merchant: '',
        merchant_order_no: '',
      }
      this.page.page = 1
      this.query()
    },
    get_sum(){
      this.startLoading("查询中。。")   
      let data = this.structure_data(0)
      data.type = 'pay'
      service_sum_api(data).then((res)=>{
        this.endLoading()
        this.all_info = res.data
        this.all_info.settlement = this.all_info.amount - this.all_info.charge
      })
    },
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>

<style>
.el-table .proccess-row {
  background: #9cc2fa;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.proccess{
    height: 30px;
    width: 50px;
    background-color: rgb(148, 95, 248);
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.fail{
    height: 30px;
    width: 50px;
    background-color: red;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.success_class{
  height: 20px;
}
</style>

