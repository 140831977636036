<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">资金信息</span>    
        </div>
      <!-- <div style="display: flex; margin-bottom: 10px;" class="maincolor"> -->
        <div style="display: flex; margin-bottom: 10px;" class="maincolor">
        <div>
          <div class="el-icon-coin title">商户总余额：</div>
          <div class="title bigvalue">{{merchant_info.amount}}</div>
        </div>

        <!-- <div>
          <div class="el-icon-user-solid title">总冻结金额：</div>
          <div class="title bigvalue">{{merchant_info.frozen}}</div>
        </div> -->
      </div>
      <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">商户信息</span>    
      </div>
      <div class="maincolor">
        <div class="el-icon-user-solid title">商户号：</div>
        <div class="title value">{{merchant_info.ab}}</div>
        <div class="el-icon-key title">安全秘钥(KEY)：</div>
        <div class="title value">{{merchant_info.secret_key}}</div>
        <div class="el-icon-eleme title">接口地址：</div>
        <div class="title value">{{merchant_info.gateway}}</div>
        <div class="el-icon-document title">接口文档：</div>
        <div class="title value">{{merchant_info.doc_add}} <a :href="merchant_info.doc_add" target="_blank"><div class="el-icon-link" /></a></div>
      </div>
    </div>
</template>

<script>
import { merchant_info_api }from '../network/api/merchant'
export default {
  name: "merchanthome",
  components: {
  },
  data(){
    return{  
      merchant_info: {
        amount: '',
        frozen: '',
        merchant_no: '',
        secret_key: '',        
        gateway: '',
        doc_add: '',        
      },
 
      // callback: '127.0.0.1'
    }
  },
  created() {
    this.query();
  },
  methods: {
    query(){   
      this.startLoading("载入中")    
      merchant_info_api().then((res)=>{
        this.endLoading()
        if (res.code === 200) {
          this.merchant_info = res.data
          window.sessionStorage.setItem("manual", this.merchant_info.manual? 1:0);
          window.sessionStorage.setItem("country", this.merchant_info.country);
          window.sessionStorage.setItem("call_method", this.merchant_info.call_method);
          this.merchant_info.amount = this.$globalJs.format_digit(this.merchant_info.balance)
    }else this.$message.error(res.message)
      })
    }
    
  },
};

</script>

<style scoped>
.title{
  margin: 10px 0 0 10px;  
  padding: 10px;
  font-size: 20px;
}
.value{
  font-size: 26px;
}
.bigvalue{
  font-size: 38px;
}
.maincolor{
  background-color: #eeeff0;
  color: #1ba794;
  margin-top: 10px;
}
</style>

