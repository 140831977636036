const _config = {
    base_url : "",
  }
  if(process.env.NODE_ENV == "development"){
    _config.base_url = "http://www.hq-pay.net/test/";
  }else if(process.env.NODE_ENV == "production"){
    _config.base_url = "http://www.hq-pay.net/api/";
  }

  export default {
    ..._config
  }
