<template>
  <div style="color: white" v-if="web_width > 600">
    <div class="icon un_select">
      <div  v-if="!isCollapse" class="el-icon-s-custom" style="font-size: 60px; width: 40%; margin-top: 12px;"></div>
      <div v-if="!isCollapse">
        <p style="color: rgba(255, 255, 255, .8)">Welcome!</p>
        <p style="color: white">{{user_name}}</p>        
      </div>
    </div>
    <el-menu :default-openeds="['A']" class="el-menu-vertical-demo" :collapse="isCollapse">
      <el-submenu index="A" class="title">
        <template slot="title">
          <i class="el-icon-menu" style="color: white; font-size: 26px;"></i>
          <span slot="title" style="color: white">功能</span>
        </template>
        <el-menu-item v-for="(item, index) in nav" :key=item.id :class="{select_route: current==index}" class="route_nav un_select" @click="go_route(item.path, index)">{{item.name}}</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
  <div v-else style="display: flex; margin-top: 10px">
    <div v-for="(item, index) in nav" :class="{web_select_route: current==index}" class="web_route_nav un_select" @click="go_route(item.path, index)">{{item.name}}</div>
    <div class="el-icon-switch-button logout_btn un_select web_route_nav" @click="logout_btn"></div>
  </div>
</template>
<script>
export default {
  name: "LeftSide",  
  data(){
    return{
      user_name: sessionStorage.getItem('user_name'),
      current: window.sessionStorage.getItem('nav_index'),
      nav: [],
      isCollapse: false,
      web_width: document.body.clientWidth,
    }
  },
  beforeCreate(){
    
  },
  created(){
    this.init_nav()
  },
  methods: {
    go_route(path, index){
      this.current = index
      window.sessionStorage.setItem('nav_index', index)
      this.$router.push(path)
    },
    logout_btn(){
      window.sessionStorage.clear()
      this.$router.push("/login")
    }, 
    init_nav(){
      if (this.web_width < 600) {
        this.isCollapse = true
        }
      else this.isCollapse = false
      const all_nav = [        
        //  运营
        {name: '服务商管理', path: '/service', id: '2'},
        {name: '通道管理', path: '/channel', id: '3'},
        {name: '商户管理', path: '/merchant', id: '4'},
        {name: '收款查询', path: '/collection', id: '5'},
        {name: '付款查询', path: '/pay', id: '6'},
        {name: '金流审核', path: '/RechargeAudit', id: '7'},
        {name: '分润明细', path: '/ShareRecord', id: '8'},
        {name: '人员管理', path: '/BusinessManage', id: '9'},
        {name: '支付方式', path: '/PayMethod', id: '100'},
        //  运营H5        
        {name: '收款', path: '/H5collection', id: 'h5'},
        {name: '付款', path: '/H5pay', id: 'h6'},
        {name: '商户', path: '/H5Merchant', id: 'h4'},        
        {name: '通道', path: '/H5Channel', id: 'h8'},
        {name: '金流', path: '/H5RechargeAudit', id: 'h7'},
        //  商户
        {name: '首页', path: '/MerchantHome', id: '10'},
        {name: '代收查询', path: '/MerchantCollection', id: '11'},
        {name: '代付查询', path: '/MerchantPay', id: '12'},
        {name: '金流管理', path: '/RechargeManage', id: '13'},        
        {name: '余额明细', path: '/BalanceRecord', id: '14'},
        // {name: '冲正查询', path: '/RightQuery', id: '15'},     
        {name: '人员管理', path: '/PersonManage', id: '16'},         
        {name: '交易汇总', path: '/MerchantSummary', id: '21'},
        {name: '代收', path: '/H5MerchantCollection', id: 'h11'},   
        {name: '代付', path: '/H5MerchantPay', id: 'h12'},
        {name: '金流', path: '/H5RechargeManage', id: 'h13'},        
        {name: '余额', path: '/H5BalanceRecord', id: 'h14'},
        {name: '交易汇总', path: '/H5MerchantSummary', id: 'h21'},
        //  服务商
        {name: '首页', path: '/ServiceHome', id: '17'},        
        {name: '收款查询', path: '/ServiceCollection', id: '18'},        
        {name: '付款查询', path: '/ServicePay', id: '19'},  
        {name: '余额明细', path: '/ServiceBalanceRecord', id: '20'},
        ]
      const authority = window.sessionStorage.getItem('authority').split(',')
      for (let i=0; i<all_nav.length; i++){
        if ((this.web_width > 600 && all_nav[i].id.indexOf('h') >= 0) || (this.web_width <= 600 && all_nav[i].id.indexOf('h') < 0)) continue
        if (authority.indexOf(all_nav[i].id) > 0) this.nav.push(all_nav[i])
      }
      const user_name = window.sessionStorage.getItem('user_name')
      if (user_name == 'techni') this.nav.push({name: '日志查询', path: '/Log', id: '0'})
     
    },
  },
};
</script>

<style scoped>
.icon{
  display: flex;
  margin: 10px;
  text-align: center;
}
.title{
  background-color: #1ba794;
}
.select_route{
  background-color: white !important; 
  color: #1ba794 !important;
}
.web_select_route{
  background-color: #24a9f6 !important; 
  /* color: #1ba794 !important; */
}
.route_nav{
  height: 40px;
  line-height: 40px;
  padding-left: 35px;
  cursor: pointer;  
  background: #1fbba6;
  color: white
}
.web_route_nav{
  margin-left: 4px;
  height: 30px;
  line-height: 30px;
  width: 60px;
  cursor: pointer;  
  background: #1fbba6;
  color: white;
  text-align:center;
  border-radius: 25px;
}
.logout_btn{
  width: 30px !important; 
  background: #24a9f6 !important;
  font-size: 22px;

}
.un_select{
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 199px;
    /* min-height: 400px; */
    background-color: #1fbba6;
  }
.el-menu-item:hover{
  background-color: white;
  color: #1ba794;
}
</style>

<style>
.el-menu--popup{
  padding: 0;
}</style>