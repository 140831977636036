import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login.vue'
import MerchantHome from '../views/MerchantHome.vue'
import Merchant from '../views/MerchantManage.vue'
import Service from '../views/ServiceManage.vue'
import Channel from '../views/ChannelManage.vue'
import Collection from '../views/Collection.vue'
import Pay from '../views/Pay.vue'
import RechargeAudit from '../views/RechargeAudit.vue'
import RechargeManage from '../views/RechargeManage.vue'
import ShareRecord from '../views/ShareRecord.vue'
import MerchantCollection from '../views/MerchantCollection.vue'
import BusinessManage from '../views/BusinessManage.vue'
import MerchantPay from '../views/MerchantPay.vue'
import RightQuery from '../views/RightQuery.vue'
import BalanceRecord from '../views/BalanceRecord.vue'
import PersonManage from '../views/PersonManage.vue'
import ServiceHome from '../views/ServiceHome.vue'
import ServiceCollection from '../views/ServiceCollection.vue'
import ServicePay from '../views/ServicePay.vue'
import ServiceBalanceRecord from '../views/ServiceBalanceRecord.vue'
import H5Merchant from '../views/H5MerchantManage.vue'
import H5Collection from '../views/H5Collection.vue'
import H5Pay from '../views/H5Pay.vue'
import H5RechargeAudit from '../views/H5RechargeAudit.vue'
import PayMethod from '../views/PayMethod.vue'
import H5Channel from '../views/H5Channel.vue'
import H5MerchantCollection from '../views/H5MerchantCollection.vue'
import H5MerchantPay from '../views/H5MerchantPay.vue'
import H5BalanceRecord from '../views/H5BalanceRecord.vue'
import H5RechargeManage from '../views/H5RechargeManage.vue'
import Logs from '../views/logs.vue'
import MerchantSummary from '../views/MerchantSummary'
import H5MerchantSummary from '../views/H5MerchantSummary'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);
const router = new Router({
  linkActiveClass: 'active',
  routes: [
    {path: '/', redirect: '/Login', },
    {path: '/Login', component: Login, name: '1'},    
    
    {path: '/Service', component: Service, name: '2'},
    {path: '/Channel', component: Channel, name: '3'},
    {path: '/Merchant', component: Merchant, name: '4'},        
    {path: '/Collection', component: Collection, name: '5'},
    {path: '/Pay', component: Pay, name: '6'},
    {path: '/RechargeAudit', component: RechargeAudit, name: '7'},   
    {path: '/ShareRecord', component: ShareRecord, name: '8'},
    {path: '/BusinessManage', component: BusinessManage, name: '9'},
    {path: '/PayMethod', component: PayMethod, name: '100'},
    {path: '/MerchantHome', component: MerchantHome, name: '10'},
    {path: '/MerchantCollection', component: MerchantCollection, name: '11'},
    {path: '/MerchantPay', component: MerchantPay, name: '12'},
    {path: '/RechargeManage', component: RechargeManage, name: '13'},
    {path: '/RightQuery', component: RightQuery, name: '15'},    
    {path: '/BalanceRecord', component: BalanceRecord, name: '14'},
    {path: '/PersonManage', component: PersonManage, name: '16'},
    {path: '/MerchantSummary', component: MerchantSummary, name: '21'},    
    {path: '/H5MerchantSummary', component: H5MerchantSummary, name: 'h21'},    

    {path: '/ServiceHome', component: ServiceHome, name: '17'},
    {path: '/ServiceCollection', component: ServiceCollection, name: '18'},
    {path: '/ServicePay', component: ServicePay, name: '19'},
    {path: '/ServiceBalanceRecord', component: ServiceBalanceRecord, name: '20'},    
    {path: '/H5Collection', component: H5Collection, name: 'h5'},
    {path: '/H5Pay', component: H5Pay, name: 'h6'},
    {path: '/H5RechargeAudit', component: H5RechargeAudit, name: 'h7'},   
    {path: '/H5Merchant', component: H5Merchant, name: 'h4'},
    {path: '/H5Channel', component: H5Channel, name: 'h8'},
    {path: '/H5MerchantCollection', component: H5MerchantCollection, name: 'h11'},
    {path: '/H5MerchantPay', component: H5MerchantPay, name: 'h12'},
    {path: '/H5RechargeManage', component: H5RechargeManage, name: 'h13'},
    {path: '/H5BalanceRecord', component: H5BalanceRecord, name: 'h14'},
    {path: '/Log', component: Logs, name: '0'},    
    
  ]
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/Login') return next();//访问登录页面直接放行
  // 获取token
  const tokenStr = window.sessionStorage.getItem('user_name');
  if (!tokenStr) return next('/Login');
  const authority = window.sessionStorage.getItem('authority').split(','); 
  if (authority.indexOf(to.name) < 0) {console.log('无权限'); return next(from.path)}
  next();
})
export default router
