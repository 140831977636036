import request from "../request";
export function merchant_query_api(data) {
  return request({
    url: '/merchant/merchant_query',
    method:'get',
    params: data
  })
}
export function merchant_query_export_api(data) {
  return request({
    url: '/merchant/merchant_query_export',
    method:'post',
    data: data,
    responseType: 'blob'
  })
}
export function get_service_api(data) {
  return request({
    url: '/merchant/get_service',
    method:'get',
    params: data
  })
}
export function reg_merchant_api(data) {
  return request({
    url: '/merchant/reg_merchant',
    method:'post',
    data: data,
  })
}
export function merchant_manage_api(data) {
  return request({
    url: '/merchant/merchant_manage',
    method:'post',
    data: data,
  })
}
export function service_query_api(data) {
  return request({
    url: '/merchant/service_query',
    method:'get',
    params: data
  })
}
export function merchant_info_api() {
  return request({
    url: '/merchant/merchant_info',
    method:'get',
  })
}
export function reg_service_api(data) {
  return request({
    url: '/merchant/reg_service',
    method:'post',
    data: data,
  })
}
export function service_manage_api(data) {
  return request({
    url: '/merchant/service_manage',
    method:'post',
    data: data,
  })
}
export function get_wallet_api(data) {
  return request({
    url: '/merchant/get_wallet',
    method:'get',
    params: data
  })
}
export function get_channel_api(data) {
  return request({
    url: '/merchant/get_channel',
    method:'get',
    params: data
  })
}
export function get_channel_name_api() {
  return request({
    url: '/merchant/get_channel_name',
    method:'get',
  })
}
export function create_wallet_api(data) {
  return request({
    url: '/merchant/create_wallet',
    method:'post',
    data: data,
  })
}
export function edit_wallet_api(data) {
  return request({
    url: '/merchant/edit_wallet',
    method:'post',
    data: data,
  })
}
export function frozen_wallet_amount_api(data) {
  return request({
    url: '/merchant/frozen_wallet_amount',
    method:'post',
    data: data,
  })
}
export function pay_api(data) {
  return request({
    url: '/merchant/pay',
    method:'get',
    params: data
  })
}
export function transaction_summary_api(data) {
  return request({
    url: '/merchant/summary',
    method:'get',
    params: data
  })
}
export function collection_success_api(data) {
  return request({
    url: '/merchant/collection_success',
    method:'post',
    data: data,
  })
}
export function resend_notice_api(data) {
  return request({
    url: '/merchant/resend_notice',
    method:'post',
    data: data,
  })
}
export function cash_api(data) {
  return request({
    url: '/merchant/cash',
    method:'get',
    params: data
  })
}
export function manual_cash_api(data) {
  return request({
    url: '/merchant/manual_cash',
    method:'post',
    data: data,
  })
}
export function all_recharge_api(data) {
  return request({
    url: '/merchant/all_recharge',
    method:'get',
    params: data
  })
}
export function mer_recharge_api(data) {
  return request({
    url: '/merchant/mer_recharge',
    method:'get',
    params: data
  })
}
export function submit_recharge_api(data) {
  return request({
    url: '/merchant/submit_recharge',
    method:'post',
    data: data,
  })
}
export function audit_recharge_api(data) {
  return request({
    url: '/merchant/audit_recharge',
    method:'post',
    data: data,
  })
}
export function share_recorde_api(data) {
  return request({
    url: '/merchant/share_recorde',
    method:'get',
    params: data
  })
}
export function query_business_api(data) {
  return request({
    url: '/merchant/query_business',
    method:'get',
    params: data
  })
}
export function merchant_pay_api(data) {
  return request({
    url: '/merchant/merchant_pay',
    method:'get',
    params: data
  })
}
export function merchant_cash_api(data) {
  return request({
    url: '/merchant/merchant_cash',
    method:'get',
    params: data
  })
}
export function right_query_api(data) {
  return request({
    url: '/merchant/right_query',
    method:'get',
    params: data
  })
}
export function balance_recode_api(data) {
  return request({
    url: '/merchant/balance_recode',
    method:'get',
    params: data
  })
}
export function cal_query_api(data) {
  return request({
    url: '/merchant/cal_query',
    method:'get',
    params: data
  })
}
export function set_default_wallet_api(data) {
  return request({
    url: '/merchant/set_default_wallet',
    method:'post',
    data: data,
  })
}
export function transfer_balance_api(data) {
  return request({
    url: '/merchant/transfer_balance',
    method:'post',
    data: data,
  })
}
export function edit_service_api(data) {
  return request({
    url: '/merchant/edit_service',
    method:'post',
    data: data,
  })
}
export function munual_cash_api(data) {
  return request({
    url: '/merchant/manual_transfer',
    method:'post',
    data: data,
  })
}