<template>
  <div style="width: 100%; height: 100%" id="nav_main">
    <div class="nav_header">
      <div class="icon un_select">环球Pay</div>       
      <el-dropdown class="user un_select">
        <i><div class="el-icon-s-custom"></div>{{user_name}}<div class="el-icon-caret-bottom"></div></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout_select" class="un_select"><div class="el-icon-edit-outline"></div>修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="logout_btn" class="un_select"><div class="el-icon-switch-button"></div>退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      title="修改密码"
      :close-on-click-modal="false"
      :visible.sync="reset_pw_dialog"      
      width="400px"      
    >
      <!--      内容主体区域-->
      <el-form
        :model="reset_pw"
        :rules="reset_pw_FormRules"
        ref="reset_pw_ref"
        label-width="auto"
        class="dialog"
      >
        <el-form-item label="新密码1" prop="password">
          <el-input
            v-model="reset_pw.password"
            placeholder="请输入新密码"
            type="password"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="新密码2" prop="password1">
          <el-input
            v-model="reset_pw.password1"
            placeholder="请再次输入新密码"
            type="password"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="谷歌验证码" prop="gtoken">
          <el-input
            v-model="reset_pw.gtoken"
            placeholder="请再次输入新密码"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">        
        <el-button type="primary" @click="reset_pw_btn">确 定</el-button>
        <el-button @click="reset_pw_dialog = false">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import { reset_pw_api } from "../network/api/user";
export default {
  name: "NavBar",
  mounted() {            
  },
  data() {
    return {
      reset_pw_FormRules: {
        // 验证密码规则
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
        password1: [
          { required: true, message: "请再次出入新密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
        gtoken: [
          { required: true, message: "谷歌验证码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 6,
            message: "长度必须6位",
            trigger: "blur",
          },
        ],
      },

      reset_pw: {
        password: "",
        password1: "",
        gtoken: "",
      },
      reseting: false,
      logout: false,
      menus: [
        {
          name: '修改密码',
          class: 'el-icon-setting li'
        },
        {
          name: "退出",
          class: "el-icon-user li"
        }],
      user_name: sessionStorage.getItem('user_name'),
      reset_pw_dialog: false,
    };
  },

  created() {
  },  
  methods: {
    change_logout(){
      this.logout = !this.logout;
      this.reset_pw.password = '';
      this.reset_pw.password1 = '';
      this.reset_pw.gtoken = '';
    },
    logout_select() {
      this.reset_pw_dialog = true      
    },
    logout_btn(){
      window.sessionStorage.clear()
      this.$router.push("/login")
    },    
    reset_pw_btn(){
      this.$refs.reset_pw_ref.validate((valid) => {
        if (!valid) return;
        if (this.reseting) return;
        this.reseting = true;
        this.startLoading("重置中。。")
        if (this.reset_pw.password != this.reset_pw.password1){
           this.$message.error("输入的两次新密码不一致, 请重新输入");
        }
        let data = {
          password: this.$md5(this.reset_pw.password),
          gtoken: this.reset_pw.gtoken,
          type: "own"
        }
        reset_pw_api(data).then((res) => {
          this.reseting=false
          this.endLoading()
          if (res.code === 200) {
            this.$message.success("修改成功, 请重新登录");
            this.logout_btn()
          } else if (res.code === 407) {
            this.$message.error("谷歌验证码错误")
          }
          else {
            return this.$message.error(res.message);
          }
        });

      })
    }
  },
};
</script>

<style scoped>
.nav_header {
  width: 100%;
  max-width: 100%;
  height: 55px;
  position: relative;
}
.icon{
  float: left;
  width: 100px;
  height: 60px;
  font-size: 22px;
  color: #ecf0f1;
  line-height: 60px;
  text-align: center;
  margin-left: 40px;
}
.user{
  float: right;
  height: 60px;
  font-size: 22px;
  color: #ecf0f1;
  line-height: 60px;
  padding: 0 10px;
  cursor: pointer;
  text-align: center;  
}
.right{
  width: 120px;
  position: absolute;
  background-color: rgb(122, 122, 196);
  color: white;
  top: 43px;
  right: 1px;
  line-height: 60px;
  text-align: left;
  cursor: pointer;
}
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: center;  
}
.un_select{
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
</style>

