<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">首页</span>    
        </div>
      <div class="bigtitle">分润概况</div>
      <div class="maincolor">        
        <div class="box" style="width: 180px">
          <div class="title">历史收款分润总额：</div>
          <div class="title">{{service_info.history_collection_charge}}</div>
        </div>        
        <div class="box" style="width: 180px">
          <div class="title" >今日收款分润总额：</div>
          <div class="title">{{service_info.today_collection_charge}}</div>
        </div>
        <div class="box" style="width: 180px">
          <div class="title">昨日收款分润总额：</div>
          <div class="title">{{service_info.yestoday_collection_charge}}</div>
        </div>
      </div>
      <div class="maincolor">
        <div class="box" style="width: 180px">
          <div class="title" >历史代付分润总额：</div>
          <div class="title">{{service_info.history_wd_charge}}</div>
        </div>        
        <div class="box" style="width: 180px">
          <div class="title">今日代付分润总额：</div>
          <div class="title">{{service_info.today_wd_charge}}</div>
        </div>
        <div class="box" style="width: 180px">
          <div class="title">昨日代付分润总额：</div>
          <div class="title">{{service_info.yestoday_wd_charge}}</div>
        </div>
      </div>


      <div class="bigtitle">代付概况</div>
      <div class="maincolor">        
        <div class="box">
          <div class="title">历史代付总额：</div>
          <div class="title">{{service_info.history_wd_amount}}</div>
        </div>        
        <div class="box">
          <div class="title">历史代付总笔数：</div>
          <div class="title">{{service_info.history_wd_times}}</div>
        </div>        
      </div>
      <div class="maincolor">
        <div class="box">
            <div class="title">今日代付总额：</div>
            <div class="title">{{service_info.today_wd_amount}}</div>
        </div>
        <div class="box">
          <div class="title">昨日代付总额：</div>
          <div class="title">{{service_info.yestoday_wd_amount}}</div>
        </div>        
        <div class="box">
          <div class="title">今日代付总笔数：</div>
          <div class="title">{{service_info.today_wd_times}}</div>
        </div>
        <div class="box">
          <div class="title">昨日代付总笔数：</div>
          <div class="title">{{service_info.yestoday_wd_times}}</div>
        </div>
      </div>

      <div class="bigtitle">代收概况</div>
      <div class="maincolor">        
        <div class="box">
          <div class="title">历史代收总额：</div>
          <div class="title">{{service_info.history_collection_amount}}</div>
        </div>        
        <div class="box">
          <div class="title">历史代收总笔数：</div>
          <div class="title">{{service_info.history_collection_times}}</div>
        </div>        
      </div>
      <div class="maincolor">
        <div class="box">
            <div class="title">今日代收总额：</div>
            <div class="title">{{service_info.today_collection_amount}}</div>
        </div>
        <div class="box">
          <div class="title">昨日代收总额：</div>
          <div class="title">{{service_info.yestoday_collection_amount}}</div>
        </div>        
        <div class="box">
          <div class="title">今日代收总笔数：</div>
          <div class="title">{{service_info.today_collection_times}}</div>
        </div>
        <div class="box">
          <div class="title">昨日代收总笔数：</div>
          <div class="title">{{service_info.yestoday_collection_times}}</div>
        </div>
      </div>

      <div class="bigtitle">商户概况</div>
      <div class="maincolor">        
        <div class="box" style="width: 180px">
          <div class="title">商户总数：</div>
          <div class="title">{{service_info.total_merchant}}</div>
        </div>        
        <div class="box" style="width: 180px">
          <div class="title">今日新增商户：</div>
          <div class="title">{{service_info.today_increase}}</div>
        </div>   
        <div class="box" style="width: 180px">
          <div class="title">昨日新增商户：</div>
          <div class="title">{{service_info.yestoday_increase}}</div>
        </div>        
      </div>
    </div>
</template>

<script>
import { home_api }from '../network/api/service'
export default {
  name: "ServiceHome",
  components: {
  },
  data(){
    return{  
        service_info:{
            history_recharge_share: 0,
            history_wd_share: 0,
        }
    }
  },
  created() {
    this.query();
  },
  methods: {
    query(){   
      this.startLoading("载入中")    
      home_api().then((res)=>{
        this.endLoading()
        if (res.code === 200) {
          this.service_info = res.data
        }else this.$message.error(res.message)
      })
    }    
  },
};

</script>

<style scoped>
.bigtitle{
    margin: 10px 0 10px 15px;  
    padding: 10px;  
    font-size: 26px;  
    background-color: rgb(75, 169, 247);  
    width: 104px;
    color:rgb(213, 250, 192);
    text-align: center;
    border-radius: 10px;
}
.title{
    margin: 10px 0 10px 0;  
    padding: 5px;  
    text-align: center;
}
.box{
    background-color: rgb(210, 232, 250);
    border:1px solid rgb(75, 169, 247);
    /* margin: 5px; */
    border-radius: 10px;
}
.maincolor{
    background-color: #faf9fd;
    color: #1ba794;
    display: flex; 
    padding: 10px 0; 
    width: 570px;
    justify-content: space-around;
    opacity: 0.9;
}
</style>

