<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">代付查询</span>
        </div>

        <div style="font-size: 12px">
          <div style="display: flex; margin: 10px 0;">
              <div style="width: 460px; margin-right: 20px;">
                <span>提交时间：</span>
                <el-date-picker
                  v-model="query_condition.query_time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  size="mini">
                </el-date-picker>
              </div>
              <div style="display: flex; line-height: 30px;margin-right: 20px;">
                  <div>订单状态：</div>
                      <el-select v-model="query_condition.state" placeholder="请选择" style="width: 80px" size="mini">
                      <el-option
                          v-for="item in state_options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                      </el-select>                
              </div>
              <div style="display: flex; line-height: 30px; margin-right: 20px;">
                <span style="width: 83px;">订单号：</span>
                <el-input v-model="query_condition.order_no" placeholder="请输入订单号" size="mini"></el-input>
              </div> 
          </div>

          <div style="display: flex; margin: 10px 0;">
              <div style="display: flex; line-height: 30px; margin-right: 20px;">
                <span style="width: 36px;">户名：</span>
                <el-input v-model="query_condition.account_name" placeholder="请输入户名" size="mini" style="width: 180px"></el-input>
              </div> 
              <div style="display: flex; line-height: 30px; margin-right: 20px;">
                <span style="width: 36px;">卡号：</span>
                <el-input v-model="query_condition.card_no" placeholder="请输入卡号" size="mini" style="width: 180px"></el-input>
              </div> 
              <div style="display: flex; line-height: 30px; margin-right: 20px;">
                <span style="width: 43px;">银行：</span>
                <el-input v-model="query_condition.bank" placeholder="请输入银行" size="mini" style="width: 180px"></el-input>
              </div> 
              <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
              <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
              <el-button type="primary" @click="manual_pay" size="mini" v-if="manual == 1">手动代付</el-button>
              <div style="margin-left: 10px;">
                <el-popover
                  placement="right"
                  width="220"
                  trigger="click">
                  <div>
                    <p>金额：{{this.all_info.amount}}</p>
                    <p>手续费：{{this.all_info.charge}}</p>
                    <p>笔数：{{this.all_info.times}}</p>
                    <p>结算：{{this.all_info.settlement}}</p>
                  </div>
                  <el-button slot="reference" type="primary" @click="get_sum" size="mini" style="height: 30px !important">统计</el-button>
                </el-popover>
              </div>              
              <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
          </div>
        </div>


        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              prop="create_time"
              label="发起时间"
              width="140">
            </el-table-column>
            <el-table-column
              prop="processing_time"
              label="处理时间"
              width="140">
            </el-table-column>
            <el-table-column
              prop="merchant_order_no"
              label="订单号"
              width="200">
            </el-table-column>            
            <el-table-column
              prop="account_name"
              label="户名">
            </el-table-column>
            <el-table-column
              prop="card_no"
              label="卡号"
              width="160">
            </el-table-column>
            <el-table-column
              prop="bank"
              label="银行">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="付款金额">
            </el-table-column>
            <el-table-column
              prop="channel_charge"
              label="手续费">
            </el-table-column>
            <el-table-column
              prop="real_amount"
              label="结算金额">
            </el-table-column>
            <el-table-column
              label="备注"
              width="80">
              <template slot-scope="scope">
                <el-popover
                    placement="top-start"
                    width="140"
                    trigger="hover"
                    effect="light"  
                    :content=scope.row.remark>
                    <span slot="reference">{{scope.row.remark | ellipsis }}</span>
                </el-popover>        
              </template>
            </el-table-column>
            <el-table-column
              label="交易状态"
              width="68">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">成功</div>
                <div class='proccess' v-if="scope.row.state == 0">处理中</div>
                <div class='fail' v-if="scope.row.state == 2">失败</div>
              </template>
            </el-table-column>            
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                  <div>
                      <el-button size="mini" @click="notice_again(scope.row)" v-if="scope.row.state != 0">补发通知</el-button>
                  </div>               
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

        <el-dialog
          title="提示"
          :close-on-click-modal="false"
          :visible.sync="resend_dialog"
          width="25%"
          >
          <!--      内容主体区域-->
          <el-form
              :model="edit_infos"
              :rules="InputRules"
              ref="resend"
              label-width="120px"
              class="form_regist"
          >
              <el-form-item label="谷歌验证码" prop="gtoken">
              <el-input
                  v-model="edit_infos.gtoken"
                  placeholder="请输入谷歌验证码"
                  style="width: 100%"
                  maxlength="6"
              >
              </el-input>
              </el-form-item>
          </el-form>
          <!--      底部区域-->
          <span slot="footer" class="dialog-footer">
              <el-button @click="close_dialog">取 消</el-button>
              <el-button type="primary" @click="commit_resend"
              >确 定</el-button
              >
          </span>
        </el-dialog>  

        <el-dialog
          title="手动代付"
          :close-on-click-modal="false"
          :visible.sync="manual_dialog"
          width="25%"
          >
          <!--      内容主体区域-->
          <el-form
              :model="edit_infos"
              :rules="InputRules"
              ref="manual_cash"
              label-width="120px"
              class="form_regist"
          >   
              <div class="dialog-footer" style="margin-bottom:10px">
                <el-button @click="download_templatem">下载模板</el-button>
                <el-button type="primary" @click="upload_dialog=true">上传文件</el-button>
              </div>
              <el-form-item label="银行编码" prop="bankcode">
              <el-input
                  v-model="edit_infos.bankcode"
                  placeholder="请输入银行编码"
                  style="width: 100%"
                  maxlength="30"
              >
              </el-input>
              </el-form-item>

              <el-form-item label="户名" prop="bankcardname">
              <el-input
                  v-model="edit_infos.bankcardname"
                  placeholder="请输入客户名称"
                  style="width: 100%"
                  maxlength="50"
              >
              </el-input>
              </el-form-item>

              <el-form-item label="账号" prop="bankcardnumber">
              <el-input
                  v-model="edit_infos.bankcardnumber"
                  placeholder="请输入银行卡号"
                  style="width: 100%"
                  maxlength="30"
              >
              </el-input>
              </el-form-item>

              <el-form-item label="金额" prop="amount">
              <el-input
                  v-model="edit_infos.amount"
                  placeholder="请输入转账金额"
                  style="width: 100%"
                  maxlength="12"
              >
              </el-input>
              </el-form-item>

              <el-form-item label="谷歌验证码" prop="gtoken">
              <el-input
                  v-model="edit_infos.gtoken"
                  placeholder="请输入谷歌验证码"
                  style="width: 100%"
                  maxlength="6"
              >
              </el-input>
              </el-form-item>
          </el-form>
          <!--      底部区域-->
          <span slot="footer" class="dialog-footer">

              <el-button @click="close_dialog">取 消</el-button>
              <el-button type="primary" @click="commit_manual"
              >确 定</el-button
              >
          </span>
        </el-dialog>  

        <el-dialog
          title="上传文件"
          :close-on-click-modal="false"
          :visible.sync="upload_dialog"
          width="25%"
          >
          <!--      内容主体区域-->
          <el-form
              :model="edit_infos"
              :rules="InputRules"
              ref="resend"
              label-width="120px"
              class="form_regist"
          >

              <el-upload
                class="upload-demo"
                drag
                :headers="herders"
                :action="upload_url"
                :data="user_data"
                :on-success="upload_success"
                :on-error="upload_failed"
                :before-upload="generate_up_load_data"
                :show-file-list="false"
                accept=""
                >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传xls或者xlsx文件，且不超过100kb</div>
              </el-upload>
          </el-form>
          <!--      底部区域-->
        </el-dialog>  
        
        <el-dialog
          title="错误信息"
          :close-on-click-modal="false"
          :visible.sync="error_dialog"
          width="25%"
          >
          <!--      内容主体区域-->
          <el-form
              :model="edit_infos"
              :rules="InputRules"
              ref="resend"
              label-width="180px"
              class="form_regist"
          >
          <el-table
            :data="error_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              prop="bankcardname"
              label="户名">
            </el-table-column>
            <el-table-column
              prop="bankcardnumber"
              label="账号">
            </el-table-column>
            <el-table-column
              prop="bankname"
              label="银行编码">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="金额">
            </el-table-column>       
            <el-table-column
              label="状态">
              <template slot-scope="scope">
                <div  v-if="scope.row.state == 1">处理中</div>
                <div  v-else>{{ scope.row.state}}</div>
              </template>
            </el-table-column> 

          </el-table>

              
          </el-form>
          <!--      底部区域-->
        </el-dialog>  
  </div>
</template>

<script>
import { merchant_cash_api, resend_notice_api, cal_query_api, munual_cash_api } from "../network/api/merchant";
import {exportExcelFromFront} from "../assets/js/exportexcel"
import paging from "../components/pagenation";
import _config from "../network/baseurl"

export default {  
  name: "MerchantPay",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      manual: 0,
      country: "",
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '成功'
        },
        {
          value: 2,
          label: '失败'
        },
        {
          value: 0,
          label: '处理中'
        },
        ],
      upload_url: _config.base_url + 'merchant/batch_transfer',
      query_condition: {
        query_time: [], 
        state: "",
        account_name: '',
        order_no: '',
        card_no: '',
        bank: '',
      },        
      collection_infos: [],
      resend_dialog: false,
      manual_dialog: false,
      upload_dialog: false,
      error_dialog: false,
      edit_infos: {},
      error_infos: [],
      all_info: {
        amount: 0,
        times: 0,
        charge: 0,
        settlement: 0
      },
      herders: {},
      user_data: {},
    }
  },
  created() {
    this.query(),
    this.manual = window.sessionStorage.getItem('manual')
    this.country = window.sessionStorage.getItem('country')
    this.generate_headers()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(type=1){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      let data = {
        start_time: start_time, 
        end_time: end_time,
        state: this.query_condition.state,
        merchant_order_no:  this.query_condition.order_no,
        notice: this.query_condition.notice,
        type: 'extend'
      }
      if (type == 1) {
        data.page = this.page.page,
        data.page_size = this.page.page_size,
        data.type = 'query'
      }
      return data
    },
    query(){        
        this.all_info = {amount: 0, times: 0, charge: 0}
        this.startLoading("查询中。。")      
        
        merchant_cash_api(this.structure_data()).then((res)=>{          
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                  item.create_time = this.$globalJs.picker_time(item.create_time, 1)
                  item.processing_time = this.$globalJs.picker_time(item.processing_time, 1)
                  item.real_amount = item.amount + item.channel_charge
                  if (item.remark){item.remark = item.remark.includes('手动')? '手动': item.remark}
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      

    },   
    reset_condition(){
      this.query_condition = {
        query_time: [], 
        state: "",
        account_name: '',
        order_no: '',
        card_no: '',
        bank: '',
      }
      this.page.page = 1
      this.query()
    },
    notice_again(row){
        this.row = row
        this.resend_dialog = true
    },
    commit_resend(){
        const data = {
            merchant_order_no: this.row.merchant_order_no,
            amount: this.row.amount,
            realamount: this.row.real_amount,
            gtoken: this.edit_infos.gtoken,
            callback_url: this.row.callback_url,
            type: 'cash',
            merchant_no: window.sessionStorage.getItem('role'),
            order_state: this.row.state == '1'? '成功': '失败',
            msg: this.row.remark
        }
        resend_notice_api(data).then((res)=>{
            if (res.code === 200) {
                this.$message.success('发送成功')
                this.close_dialog()
                }
            else this.$message.error(res.message)
        })
    },
    close_dialog(){
        this.resend_dialog = false
        this.manual_dialog = false
        this.upload_dialog = false
        this.edit_infos = {}
    },
    get_sum(){
      this.startLoading("查询中。。")   
      let data = this.structure_data(0)
      data.type = 'cash'
      cal_query_api(data).then((res)=>{
        this.endLoading()
        this.all_info = res.data
        this.all_info.settlement = this.all_info.amount + this.all_info.charge
      })
    },
    manual_pay(){
      this.manual_dialog = true
    },
    commit_manual(){
      this.$refs.manual_cash.validate((valid) => {
        if (!valid) return
        const data = {
          businessnumber :"Manual-" + this.$md5("" + Date.now()),
          mer_id: window.sessionStorage.getItem('role'),
          country: window.sessionStorage.getItem('country'),
          call_method: window.sessionStorage.getItem('call_method'),
          ServerUrl: "manual",
          bankcardnumber: this.edit_infos.bankcardnumber,
          bankcardname: this.edit_infos.bankcardname,
          bankname: this.edit_infos.bankcode,
          amount: parseInt(this.edit_infos.amount),
          gtoken: this.edit_infos.gtoken,          
        }
        this.startLoading("代付中。。。")
    munual_cash_api(data).then((res)=>{
          this.endLoading()
          if (res.code === 'MSG_OK') {
            this.$message.success('发送成功')
            this.close_dialog()
            this.query()
            }
          else{
            this.$message.error(res.message)
          }
        })
      })      
    },
    download_templatem(){
        // const caption="我是heander标题"
        const headerList = ['bankcode','cardname','cardnumber', "amount"];
        // const cellList = [
        //   ['内容1','内容2','内容3'],
        //   ['内容4','内容5','内容6'],
        //   ['内容7','内容8','内容9'],
        // ];
        const exportName='转账模板'

        const params = {
          // caption,
          headerList,
          // cellList,
          exportName,
        };
        exportExcelFromFront(params);
    },
    generate_headers(){
      this.herders= {
        username: window.sessionStorage.getItem('user_name'),
        role: window.sessionStorage.getItem('role'),
        session: window.sessionStorage.getItem('session'),
      },
      this.user_data= {        
        mer_id: window.sessionStorage.getItem('role'),
        country: window.sessionStorage.getItem('country'),
        call_method: window.sessionStorage.getItem('call_method'),
      }
    },
    generate_up_load_data(file){      
      const file_name = file.name.split(".")
      console.log(file_name)
      const isexcel = ['xlsx', 'xls'].includes(file_name[file_name.length-1].toLowerCase());
      console.log(file_name[file_name.length-1])
      const isLt500 = file.size / 1024 < 100
      if (!isexcel) this.$message.error("文件不支持")
      if (!isLt500) this.$message.error("文件超过100K")
      
      return isLt500 && isexcel
    },
    upload_success(res, file, fileList){
      if (res.code == 200){
        this.$message.success('添加成功')
        this.close_dialog()
        this.query()
      }else{
        this.$message.error(res.message)
        if(res.data){
          this.error_infos = res.data
          console.log(this.error_infos)
          this.error_dialog = true
        }
      }
    },
    upload_failed(err, file, fileList){
      this.$message(err)
    }

},  
filters: {
    //文字数超出时，超出部分使用...
  ellipsis(value) {
      if (!value) return ''
      if (value.length > 10) {
          return value.slice(0, 8) + '...'
      }
      return value
  }
}
}
</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.proccess{
    height: 30px;
    width: 50px;
    background-color: rgb(148, 95, 248);
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.fail{
    height: 30px;
    width: 50px;
    background-color: red;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>


