<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 30px; line-height: 30px; padding: 0 10px; margin-bottom: 3px;" id="header">
          <span style="font-size: 16px; color: white">通道管理</span>
          <div style="float: right">
            <el-button @click="condition_query" type="primary" round size="mini">刷新</el-button>
          </div>
        </div>

        <div class="table_list">
          <el-table
            :data="channel_info"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              prop="name"
              label="通道名称"
              width="120">
            </el-table-column>
            <el-table-column
              label="状态"
              width="60">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">正常</div>
                <div class='fail' v-if="scope.row.state == 0">停用</div>
              </template>
            </el-table-column>
            <el-table-column
              label="加入时间"
              width="84">
              <template slot-scope="scope">
                <div>{{ scope.row.create_time[0]}}</div>
                <div>{{ scope.row.create_time[1]}}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button size="mini" @click="join_merchant(scope.row)" v-if="scope.row.state == 1">查看接入商户</el-button>
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>
      
    <el-dialog
        title="接入商户"
        :close-on-click-modal="false"
        :visible.sync="join_merchant_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <el-table
      :data="merchant_infos"
      style="width: 100%">
        <el-table-column
          prop="name"
          label="商户名称">
        </el-table-column>
        <el-table-column
          label="接入时间"
          width="84">
              <template slot-scope="scope">
                <div>{{ scope.row.create_time[0]}}</div>
                <div>{{ scope.row.create_time[1]}}</div>
              </template>
        </el-table-column>
        <el-table-column
          prop="recharge_per"
          label="代收手续费">
        </el-table-column>
        <el-table-column
          prop="recharge_single"
          label="代收单笔手续费">
        </el-table-column>
        <el-table-column
          prop="wd_per"
          label="代付手续费">
        </el-table-column>
        <el-table-column
          prop="wd_single"
          label="代付单笔手续费">
        </el-table-column>
      </el-table>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog">确 定</el-button>        
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { reg_channel_api,  channel_query_api, channel_manage_api, join_merchant_api, pay_method_api} from "../network/api/channel";
import paging from "../components/pagenation";

export default {  
  name: "H5Channel",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '正常'
        },
        {
          value: 0,
          label: '禁用'
        },
        ],
      payment_options: [],
      query_condition: {
        query_time: [],        
        state: "",
        name: '',
      },
      channel_info: [],    
      merchant_infos: [],
      new_channel_info: {},
      edit_infos:{},
      secret_key: '',
      plat_secret_key: '',
      add_channel_dialog: false,
      secret_dialog: false,
      recharge_dialog: false,
      join_merchant_dialog: false,
      row_infos: {
        recharge_single: 0,
        recharge_per: 0,
        wd_per: 0,
        wd_single: 0
      },      
      payment: {}
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      return {
        state: this.query_condition.state,
        start_time: start_time,
        end_time: end_time,
        _name: this.query_condition.name,
        page: this.page.page,
        page_size: this.page.page_size
      }
    },
    query(){
      this.startLoading("查询中。。")      
      channel_query_api(this.structure_data()).then((res) => {       
          this.endLoading() 
          if (res.code === 200) {
            this.channel_info = res.data.datas
            this.channel_info.forEach(item => {
            item.create_time = this.$globalJs.picker_time(item.create_time, 1).split(" ")
            })
            this.page.total = res.data.total
          }else{
            this.$message.error(res.message)
          }
    })
    },   
    reset_condition(){
      this.query_condition = {
        query_time: [],        
        state: "",
        name: '',
      }
      this.page.page = 1
      this.query()
    },
    compare_value(data){
      for (let i=0; i< data.length; i++){
        if (this.edit_infos[data[i]] != this.row[data[i]]) return false      
      }
      return true
    },
    add_channel(){
      pay_method_api({type: 'all'}).then((res)=>{
        if (res.code===200){
          this.add_channel_dialog = true
          this.payment_options = res.data
        }
      })
      
    },
    commit_add_channel(){
            this.$refs.add_channel.validate((valid) => {
            if (!valid) return;
            this.new_channel_info.api_add = this.payment.api_add
            this.new_channel_info.call_method = this.payment.method
            console.log(this.new_channel_info, this.edit_infos)
            this.startLoading('插入数据中。。')
            reg_channel_api(this.new_channel_info).then((res)=>{
              this.endLoading()
              if (res.code === 200){
                this.$message.success('添加成功')
                this.query()
                this.close_dialog()
              }else{this.$message.error(res.message)}
            })            
            })
    },
    edit_row(row, type, params){
      if (type === 'disable') row.state = 0
      else if (type === 'enable') row.state = 1
      else if (type === 'white_list') row.IP = params
      else if (type === 'edit_enc') {
        const key = ['IP', 'api_add', 'merchant_no', 'plat_secret_key', 'secret_key']
        for (let i=0; i<key.length; i++){
          row[key[i]] = params[key[i]]
        }
      }
      else if (type === 'edit_recharge') {
        const key = ['recharge_per', 'recharge_single', 'wd_per', 'wd_single']
        for (let i=0; i<key.length; i++){
          row[key[i]] = params[key[i]]
        }
      }else this.$message.error('操作错误！！')
    },
    channel_manage(row, type, params=null){
      this.startLoading('插入数据中。。')
      const data = {ab: row.ab, type: type}
      if (params) data['data'] = params
      channel_manage_api(data).then((res)=>{
        this.endLoading()
        if (res.code === 200){
          this.$message.success('修改成功')   
          this.edit_row(row, type, params)
          this.close_dialog()
        }else this.$message.error(res.message)
      })
    },
    close_dialog(){
      this.add_channel_dialog = false
      this.secret_dialog = false
      this.recharge_dialog = false
      this.join_merchant_dialog = false
      this.new_channel_info = {}
      this.edit_infos = {}
      this.merchant_infos = []
    },
    edit_encryption(row){
      this.row = row
      this.edit_infos = {}
      this.secret_dialog = true
      this.$set(this.edit_infos, 'secret_key', row.secret_key)
      this.$set(this.edit_infos, 'plat_secret_key', row.plat_secret_key)
      this.$set(this.edit_infos, 'merchant_no', row.merchant_no)
      this.$set(this.edit_infos, 'app_id', row.app_id)  
      this.$set(this.edit_infos, 'api_add', row.api_add)  
    },
    commit_encryption(){
      this.$refs.edit_encryption.validate((valid) =>{
        if (!valid) return
        if (this.compare_value(['api_add', 'app_id', 'merchant_no', 'plat_secret_key', 'secret_key'])) this.$message.warning('无修改')
        else {
          this.channel_manage(this.row, 'edit_enc', this.edit_infos)          
      }
      })
    },
    edit_recharge(row){
      this.row = row
      this.$set(this.edit_infos, 'recharge_per', '' + row.recharge_per)
      this.$set(this.edit_infos, 'recharge_single', '' + row.recharge_single)
      this.$set(this.edit_infos, 'wd_per', '' + row.wd_per)
      this.$set(this.edit_infos, 'wd_single', '' + row.wd_single)
      this.recharge_dialog = true
    },
    commit_recharge(){
      this.$refs.recharge.validate((valid) => {
        if (!valid) return
        if (this.compare_value(['recharge_per', 'recharge_single', 'wd_per', 'wd_single'])) this.$message.warning('无修改')
        else {
          this.channel_manage(this.row, 'edit_recharge', this.edit_infos)          
      }
    })
  },
    join_merchant(row){
      this.startLoading('获取数据中。。')
      join_merchant_api({channel_ab: row.ab}).then((res)=>{
        this.endLoading()
        if (res.code === 200) {
          this.merchant_infos = res.data
          this.merchant_infos.forEach(item => {
            item.create_time = this.$globalJs.picker_time(item.create_time, 1).split(" ")
            })
          this.join_merchant_dialog = true
        }else this.$message.error(res.message)
      })
    },
    change(data){
      console.log(data)
    }
},
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.fail{
    height: 30px;
    width: 50px;
    background-color: red;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>

