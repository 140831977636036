<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 30px; line-height: 30px; padding: 0 10px; margin-bottom: 3px;" id="header">
          <span style="font-size: 16px; color: white">余额明细</span>
        </div>

        <div style="display: flex;">
            <div style="display: flex; line-height: 30px;margin-right: 10px;">
                <div style="font-size: 12px">类型：</div>
                    <el-select v-model="query_condition.type" placeholder="请选择" style="width: 120px" size="mini">
                    <el-option
                        v-for="item in type_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>                
            </div>

            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
            <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>


        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }"
            :row-class-name="color_table">
            <el-table-column
              label="发起时间"
              width="84">
              <template slot-scope="scope">
                <div>{{ scope.row.create_time[0]}}</div>
                <div>{{ scope.row.create_time[1]}}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="order_no"
              label="订单号"
              width="260">
            </el-table-column>
            <el-table-column
              prop="type"
              label="发生类型">
            </el-table-column>
            <el-table-column
              prop="value"
              label="发生金额">
            </el-table-column>    
            <el-table-column
              prop="balance"
              label="余额">
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注">
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>
  </div>
</template>

<script>
import { balance_recode_api} from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "H5BalanceRecord",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      type_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: '代付',
          label: '代付'
        },
        {
          value: '代收',
          label: '代收'
        },
        {
          value: '充值',
          label: '充值'
        },
        {
          value: '冲正',
          label: '冲正'
        },
        {
          value: '提现',
          label: '提现'
        },
        {
          value: '扣除',
          label: '扣除'
        },
        ],
      query_condition: {
        query_time: [], 
        type: "",
        _wallet_name: '',
      },        
      collection_infos: [],
      edit_infos: {},
      resend_dialog: false
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      return {
        start_time: start_time, 
        end_time: end_time,
        type: this.query_condition.type,
        _wallet_name:  this.query_condition._wallet_name,
        page: this.page.page,
        page_size: this.page.page_size
      }
    },
    query(){        
        this.startLoading("查询中。。")      
        balance_recode_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1).split(" ")
                item.value = item.amount > 0? '+' + item.amount: '' + item.amount
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    reset_condition(){
      this.query_condition = {
        query_time: [], 
        type: "",
        _wallet_name: '',
      }
      this.page.page = 1
      this.query()
    },
    color_table({row}){
      return row.amount > 0? 'add-row': 'sub-row'
    },
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>
<style>
  .el-table .add-row {
    background: oldlace;
    color: red
  }

  .el-table .sub-row {
    background: #f0f9eb;
    color: green
  }
</style>


