<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">收款查询</span>
        </div>
        <div style="display: flex; margin: 10px 0; font-size: 12px;">
            <div style="width: 460px; margin-right: 20px;">
              <span>提交时间：</span>
              <el-date-picker
                v-model="query_condition.query_time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini">
              </el-date-picker>
            </div>
            <div style="display: flex; line-height: 30px; margin-right: 20px;">
                <div>订单状态：</div>
                    <el-select v-model="query_condition.state" placeholder="请选择" style="width: 80px" size="mini">
                    <el-option
                        v-for="item in state_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>                
            </div>
            <div style="display: flex; line-height: 30px; margin-right: 20px;">
              <span style="width: 61px;">订单号：</span>
              <el-input v-model="query_condition.order_no" placeholder="请输入订单号" size="mini"></el-input>
            </div> 

            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
            <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
            <div style="margin-left: 10px;">
                <el-popover
                  placement="right"
                  width="220"
                  trigger="click">
                  <div>
                    <p>金额：{{this.all_info.amount}}</p>
                    <p>手续费：{{this.all_info.charge}}</p>
                    <p>笔数：{{this.all_info.times}}</p>
                    <p>结算：{{this.all_info.settlement}}</p>
                  </div>
                  <el-button slot="reference" type="primary" @click="get_sum" size="mini" style="height: 30px !important">统计</el-button>
                </el-popover>
              </div>
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>


        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              prop="create_time"
              label="发起时间">
            </el-table-column>
            <el-table-column
              prop="processing_time"
              label="处理时间">
            </el-table-column>
            <el-table-column
              prop="merchant_order_no"
              label="订单号"
              width="260">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="充值金额">
            </el-table-column>
            <el-table-column
              prop="channel_charge"
              label="手续费">
            </el-table-column>
            <el-table-column
              prop="real_amount"
              label="结算金额">
            </el-table-column>
            <el-table-column
              label="交易状态"
              width="68">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">成功</div>
                <div class='proccess' v-else>处理中</div>
              </template>
            </el-table-column>            
            <!-- <el-table-column
              label="通知状态"
              width="68">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.notice == 1">已通知</div>
                <div class='proccess' v-else>未通知</div>
              </template>
            </el-table-column> -->
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                  <div>
                      <el-button size="mini" @click="notice_again(scope.row)" v-if="scope.row.state == 1">补发通知</el-button>
                  </div>               
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

        <el-dialog
        title="提示"
        :close-on-click-modal="false"
        :visible.sync="resend_dialog"
        width="25%"
        >
        <!--      内容主体区域-->
        <el-form
            :model="edit_infos"
            :rules="InputRules"
            ref="resend"
            label-width="120px"
            class="form_regist"
        >
            <el-form-item label="谷歌验证码" prop="gtoken">
            <el-input
                v-model="edit_infos.gtoken"
                placeholder="请输入谷歌验证码"
                style="width: 100%"
                maxlength="6"
            >
            </el-input>
            </el-form-item>
        </el-form>
        <!--      底部区域-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog">取 消</el-button>
            <el-button type="primary" @click="commit_resend"
            >确 定</el-button
            >
        </span>
        </el-dialog>  

  </div>
</template>

<script>
import { merchant_pay_api, resend_notice_api, cal_query_api} from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "MerchantCollection",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '成功'
        },
        {
          value: 0,
          label: '处理中'
        },
        ],
       notice_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '已通知'
        },
        {
          value: 0,
          label: '未通知'
        },
        ],
      query_condition: {
        query_time: [], 
        state: "",
        notice: '',
        order_no: '',
      },        
      collection_infos: [],
      edit_infos: {},
      resend_dialog: false,
      all_info: {
        amount: 0,
        times: 0,
        charge: 0,
        settlement: 0
      },
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(type=1){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      let data = {
        start_time: start_time, 
        end_time: end_time,
        state: this.query_condition.state,
        merchant_order_no:  this.query_condition.order_no,
        notice: this.query_condition.notice,
        type: 'extend',
      }
      if (type == 1) {
        data.page = this.page.page,
        data.page_size = this.page.page_size,
        data.type = 'query'
      }
      return data
    },
    query(){        
        this.all_info = {amount: 0, times: 0, charge: 0}
        this.startLoading("查询中。。")      
        merchant_pay_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1)
                item.processing_time = this.$globalJs.picker_time(item.processing_time, 1)
                item.real_amount = item.amount - item.channel_charge
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    reset_condition(){
      this.query_condition = {
        query_time: [], 
        state: "",
        order_no: '',
      }
      this.page.page = 1
      this.query()
    },
    notice_again(row){
        this.row = row
        this.resend_dialog = true
    },
    commit_resend(){
        const data = {
            merchant_order_no: this.row.merchant_order_no,
            amount: this.row.amount,
            realamount: this.row.real_amount,
            gtoken: this.edit_infos.gtoken,
            order_state: this.row.state,
            callback_url: this.row.callback_url,
            type: 'pay',
            merchant_no: window.sessionStorage.getItem('role')
        } 
        resend_notice_api(data).then((res)=>{
            if (res.code === 200) {
                this.$message.success('发送成功')
                this.close_dialog()
                }
            else this.$message.error(res.message)
        })
    },
    close_dialog(){
        this.resend_dialog = false
        this.edit_infos = {}
    },
     get_sum(){
      this.startLoading("查询中。。")   
      let data = this.structure_data(0)
      data.type = 'pay'
      cal_query_api(data).then((res)=>{
        this.endLoading()
        this.all_info = res.data
        this.all_info.settlement = this.all_info.amount - this.all_info.charge
      })
    }
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.proccess{
    height: 30px;
    width: 50px;
    background-color: rgb(148, 95, 248);
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>


