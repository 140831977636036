<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">日志查询</span>
        </div>       

        <div style="display: flex; margin: 10px 0;">              
          <div style="display: flex; line-height: 30px; margin-right: 20px;">
            <span>关键字：</span>
            <el-input v-model="keyword" placeholder="请输入关键字" size="mini" style="width: 244px"></el-input>
          </div>        
          <el-button type="primary" @click="query" size="mini">查询</el-button>
        </div>

        <div class="table_list">
          <p v-for="log in logs"> {{ log }} </p>
        </div>
  </div>
</template>

<script>
import {get_res} from "../network/request";

export default {  
  name: "log",
  components: {
  },
  data(){
    return{        
      logs: [],
      keyword: ''
    }
  },
  created() {
  },
  methods:{
    query(){         
      if (!this.keyword){
        this.$message.error("请输入关键字")
        return
      }
      this.startLoading("载入中") 
      const data = {keyword: this.keyword}
      const url = '/user/log'
      get_res(url, data).then((res)=>{
        this.endLoading()
        if (res.code == 200){
          this.logs = res.data
        }
      })
    }    
}
}

</script>



