<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 30px; line-height: 30px; padding: 0 10px; margin-bottom: 3px;" id="header">
          <span style="font-size: 16px; color: white">收款查询</span>
          <div style="float: right">
            <el-popover
                placement="top"
                width="220"
                trigger="click">
                <div>
                  <p>金额：{{this.all_info.amount}}</p>
                  <p>手续费：{{this.all_info.charge}}</p>
                  <p>笔数：{{this.all_info.times}}</p>
                  <p>结算：{{this.all_info.amount - this.all_info.charge}}</p>
                </div>
                <el-button slot="reference" type="primary" @click="get_sum" size="mini">统计</el-button>
              </el-popover>
            </div>
        </div>

        <div style="width: 300px;">
          <span style="font-size: 12px">开始日期：</span>
          <el-date-picker
            v-model="query_condition.start_time"
            type="datetime"
            range-separator="至"
            placeholder="开始日期"
            default-time="00:00:00"
            clearable
            size="mini">
          </el-date-picker>
        </div>
        <div style="width: 300px;margin: 3px 0;">
          <span style="font-size: 12px">结束日期：</span>
          <el-date-picker
            v-model="query_condition.end_time"
            type="datetime"
            range-separator="至"
            placeholder="结束日期"
            default-time="23:59:59"
            clearable
            size="mini">
          </el-date-picker>
        </div>
        
        <div style="display: flex; line-height: 30px;margin: 3px 0;">
          <span style="font-size: 12px">通道名称：</span>
          <el-input v-model="query_condition._channel" placeholder="请输入通道名称" size="mini" style="width: 214px"></el-input>
        </div> 
        <div style="display: flex; line-height: 30px;margin: 3px 0;">
          <span style="font-size: 12px">商户名称：</span>
          <el-input v-model="query_condition._merchant" placeholder="请输入商户名称" size="mini" style="width: 214px"></el-input>
        </div>
        <div style="display: flex; line-height: 30px;margin: 3px 0;">
          <span style="font-size: 12px">渠道订单号：</span>
          <el-input v-model="query_condition.channel_order_no" placeholder="请输入渠道订单号" size="mini" style="width: 204px"></el-input>
        </div>  
        <div style="display: flex; line-height: 30px;margin: 3px 0;">
          <span style="font-size: 12px">商户订单号：</span>
          <el-input v-model="query_condition.merchant_order_no" placeholder="请输入商户订单号" size="mini" style="width: 204px"></el-input>
        </div> 
                 

        <div style="display: flex; margin: 3px 0;">
                               
            <div style="display: flex; line-height: 30px">
              <div style="font-size: 12px">状态：</div>
                  <el-select v-model="query_condition.state" placeholder="请选择" style="width: 90px" size="mini">
                  <el-option
                      v-for="item in state_options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                  </el-select>                
          </div>
            <div style="display: flex; line-height: 30px;">
              <span style="font-size: 12px">金额：</span>
              <el-input v-model="query_condition.amount" placeholder="请输入金额" style="width: 114px" size="mini"></el-input>
            </div>         
            
        </div>

        <div style="display: flex; justify-content: left; width: 100%; font-size: 22px; color: red">
            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
            <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>


        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              label="状态"
              width="60">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1" @click="show_detail(scope.row)">成功</div>
                <div class='proccess' v-else @click="show_detail(scope.row)">处理中</div>
              </template>
            </el-table-column>
            <el-table-column
              label="发起时间"
              width="84">
              <template slot-scope="scope">
                <div>{{ scope.row.create_time[0]}}</div>
                <div>{{ scope.row.create_time[1]}}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="处理时间"
              width="84">
              <template slot-scope="scope">
                <div>{{ scope.row.processing_time[0]}}</div>
                <div>{{ scope.row.processing_time[1]}}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="merchant"
              label="商户名称"
              width="22">
            </el-table-column>
            <!-- <el-table-column
              prop="channel"
              label="通道名称"
              width="102">
            </el-table-column> -->
            <el-table-column
              prop="channel_order_no"
              label="渠道订单号"
              width="310">
            </el-table-column>
            <el-table-column
              prop="merchant_order_no"
              label="商户订单号"
              width="210">
            </el-table-column>
            <el-table-column
              prop="pay_amount"
              label="收款金额"
              width="82">
            </el-table-column>
            <el-table-column
              prop="channel_charge"
              label="通道手续费"
              width="80">
            </el-table-column> 
            <el-table-column
              prop="pay_realamount"
              label="结算金额"
              width="82">
            </el-table-column>
            
            <!-- <el-table-column
              prop="service"
              label="服务商名称"
              width="80">
            </el-table-column>
            <el-table-column
              prop="service_charge"
              label="服务商手续费"
              width="92">
            </el-table-column>
            <el-table-column
              prop="channel_charge"
              label="收益"
              width="92">
            </el-table-column> -->
            <el-table-column
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button size="mini" @click="success(scope.row)" v-if="scope.row.state == 0">手动成功</el-button>
                <el-button size="mini" @click="notice(scope.row)" v-if="scope.row.state == 1">补发通知</el-button>
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
          <!-- <div style="display: flex; background-color: #1fbba6; width: 100%; height: 40px; line-height: 40px; color: white; padding: 10px;"> 
              <div style="width: 180px">金额合计: {{ this.all_info.amount }} </div>
              <div style="width: 180px">笔数合计: {{ this.all_info.times }} </div>
              <div style="width: 180px">手续费合计: {{ this.all_info.charge }} </div>
              <el-button size="mini" @click="get_sum">获取数据</el-button>  
          </div> -->
        </div>

        <el-dialog
        title="提示"
        :visible.sync="success_dialog"
        width="100%"
        >
        <!--      内容主体区域-->
        <el-form
            :model="edit_infos"
            :rules="InputRules"
            ref="success"
            label-width="120px"
            class="form_regist"
        >
            <el-form-item label="渠道订单号" prop="channel_order_no">
            <el-input
                v-model="edit_infos.channel_order_no"
                placeholder="请输入渠道订单号"
                style="width: 100%"
            >
            </el-input>
            </el-form-item>
            <el-form-item label="谷歌验证码" prop="gtoken">
            <el-input
                v-model="edit_infos.gtoken"
                placeholder="请输入谷歌验证码"
                style="width: 100%"
                maxlength="6"
            >
            </el-input>
            </el-form-item>
        </el-form>
        <!--      底部区域-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog">取 消</el-button>
            <el-button type="primary" @click="commit_success"
            >确 定</el-button
            >
        </span>
        </el-dialog>   

        <el-dialog
        title="提示"
        :visible.sync="resend_dialog"
        width="100%"
        >
        <!--      内容主体区域-->
        <el-form
            :model="edit_infos"
            :rules="InputRules"
            ref="resend"
            label-width="120px"
            class="form_regist"
        >
            <el-form-item label="谷歌验证码" prop="gtoken">
            <el-input
                v-model="edit_infos.gtoken"
                placeholder="请输入谷歌验证码"
                style="width: 100%"
                maxlength="6"
            >
            </el-input>
            </el-form-item>
        </el-form>
        <!--      底部区域-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog">取 消</el-button>
            <el-button type="primary" @click="commit_resend"
            >确 定</el-button
            >
        </span>
        </el-dialog>   

        <el-dialog
        title="订单详情"
        :visible.sync="show_detail_dialog"
        width="100%"
        size="mini"        
        >
        <!--      内容主体区域-->
        <el-descriptions border :column=2>
          <el-descriptions-item label="发起时间" v-if="row.create_time"><p>{{row.create_time[0]}}</p>{{row.create_time[1]}}</el-descriptions-item>
          <el-descriptions-item label="发起时间" v-else> - </el-descriptions-item>
          <el-descriptions-item label="处理时间" v-if="row.processing_time"><p>{{row.processing_time[0]}}</p>{{row.processing_time[1]}}</el-descriptions-item>
          <el-descriptions-item label="处理时间" v-else> - </el-descriptions-item>
          <el-descriptions-item label="状态">
            <div class='success' v-if="row.state == 1">成功</div>
            <div class='proccess' v-if="row.state == 0">处理中</div>
          </el-descriptions-item>
          <el-descriptions-item label="商户名称">{{row.merchant}}</el-descriptions-item>
          <el-descriptions-item label="通道名称">{{row.channel}}</el-descriptions-item>
          <el-descriptions-item label="通道订单号">{{row.channel_order_no}}</el-descriptions-item>
          <el-descriptions-item label="商户订单号">{{row.merchant_order_no}}</el-descriptions-item>
          <el-descriptions-item label="付款金额">{{row.amount}}</el-descriptions-item>
          <el-descriptions-item label="通道手续费">{{row.channel_charge}}</el-descriptions-item>
          <el-descriptions-item label="服务商">{{row.service}}</el-descriptions-item>
          <el-descriptions-item label="服务商手续费">{{row.service_charge}}</el-descriptions-item>
          <el-descriptions-item label="备注">{{row.remark}}</el-descriptions-item>
        </el-descriptions>
        <!--      底部区域-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog">关闭</el-button>
        </span>
        </el-dialog>  

  </div>
</template>

<script>
import { pay_api, collection_success_api, resend_notice_api, cal_query_api } from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "h5collection",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '成功'
        },
        {
          value: 0,
          label: '未支付'
        },
        ],
      query_condition: {
        start_time: undefined,
        end_time: undefined,
        state: "",
        _merchant: '',
        _service: '',
        _merchant_order_no: '',
        _channel_order_no: '',
        _channel: '',
        amount: '',
      },        
      collection_infos: [],
      success_dialog: false,
      resend_dialog: false,
      show_detail_dialog: false,
      edit_infos: {},
      row: {},
      all_info: {
        amount: 0,
        times: 0,
        charge: 0
      },
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(type=1){
      let data = {
        start_time: this.$globalJs.picker_time(this.query_condition.start_time, 0), 
        end_time: this.$globalJs.picker_time(this.query_condition.end_time, 0), 
        state: this.query_condition.state,
        _merchant:  this.query_condition._merchant,
        _service:  this.query_condition._service,
        _merchant_order_no:  this.query_condition.merchant_order_no,
        _channel_order_no:  this.query_condition.channel_order_no,
        _channel:  this.query_condition._channel,
        amount:  this.query_condition.amount,
        type: 'extend'
      }
      if (type == 1) {
        data.page = this.page.page,
        data.page_size = this.page.page_size,
        data.type = 'query'
      }
      return data
    },
    query(){        
        this.all_info = {amount: 0, times: 0, charge: 0, settment: 0}
        this.startLoading("查询中。。")      
        pay_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1).split(" ")
                item.processing_time = this.$globalJs.picker_time(item.processing_time, 1).split(" ")
                item.pay_amount = this.$globalJs.format_digit(item.amount)
                item.pay_realamount = this.$globalJs.format_digit(item.amount - item.channel_charge)
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    reset_condition(){
      this.page.page = 1
        this.query_condition = {
        start_time: undefined,
        end_time: undefined, 
        state: "",
        _merchant: '',
        _service: '',
        _merchant_order_no: '',
        _channel_order_no: '',
        _channel: '',
        amount: '',        
      }
      this.page.page = 1
      this.query()
    },
    success(row){
        this.row = row
        this.edit_infos = {
            amount: row.amount,
            merchant_order_no: row.merchant_order_no,    
        }
        this.success_dialog = true
    },

    commit_success(){
        this.$refs.success.validate((valid) => {
            if (!valid) return
            this.startLoading('插入数据中')
            collection_success_api(this.edit_infos).then((res)=>{
                this.endLoading()
                if (res.code === 200){
                    this.$message.success('修改成功')
                    this.row.state = 1
                    this.row.channel_order_no = '手' + this.edit_infos.channel_order_no
                    this.row.processing_time = this.$globalJs.picker_time(this.$globalJs.set_hour(new Date(), 8))
                    this.close_dialog()
                }else this.$message.error(res.message)
            })        
        })
    },
    notice(row){
        this.edit_infos = {
            merchant_no: row.merchant_no,
            merchant_order_no: row.merchant_order_no,
            amount: row.amount,
            realamount: row.amount - row.channel_charge,    
            callback_url: row.callback_url,
            type: 'pay',
            order_state: row.state,
            msg: row.remark.includes('手动')? '手动': row.remark
        }
        this.resend_dialog = true
    },
    commit_resend(){
        this.$refs.resend.validate((valid) => {
            if (!valid) return
            resend_notice_api(this.edit_infos).then((res)=>{
                if (res.code === 200) {
                    this.$message.success('补发成功') 
                    this.close_dialog()
                    }
                else this.$message.error(res.message)
            })        
        })
    },
    close_dialog(){
        this.edit_infos = {}
        this.success_dialog = false
        this.resend_dialog = false
        this.show_detail_dialog = false
    },
    get_sum(){
      this.startLoading("查询中。。")   
      let data = this.structure_data(0)
      data.type = 'pay'
      cal_query_api(data).then((res)=>{
        this.endLoading()
        this.all_info = res.data
      })
    },
    show_detail(row){
      this.row = row
      this.show_detail_dialog = true
    },
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>

<style>
.el-table .success-row {
  background: #80e4e7;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.proccess{
    height: 30px;
    width: 50px;
    background-color: rgb(148, 95, 248);
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.el-date-picker{
  width: 280px !important; 
}
.el-picker-panel__body{
  width: 280px; 
  }
.el-date-picker__header{
  margin: 3px !important;  
}
.el-date-picker__time-header{
  padding: 5px 1px 5px !important
}
.el-date-picker__header-label{
  font-size: 12px !important;
  padding: 0, 1px !important;
  line-height: 16px !important
}
.el-date-table{
  padding: 1px !important;
}
.el-picker-panel{
  line-height: 5px !important
}
.el-date-table th{
  padding: 2px !important;
  font-weight: 200 !important
}
.el-date-table td{
  width: 20px !important;
  padding: 1px 0 !important;
}
.el-date-table td div{
  padding: 0 !important
}
.el-picker-panel__icon-btn{
  margin-top: 1px !important;
}
.el-date-picker table{
  width: 260px !important
}
.el-date-picker .el-picker-panel__content{
  width: 260px !important
}
.el-picker-panel__body-wrapper{
  width: 280px !important;
}
.el-time-panel{
  width: 138px !important;
}
.el-button--text{
  padding: 7px 15px !important; 
}

</style>

