<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">交易汇总</span>
        </div>
        <div style="display: flex; margin: 10px 0; font-size: 12px;">
            <div style="width: 460px; margin-right: 20px;">
              <span>查询日期：</span>
              <el-date-picker
                  v-model="query_condition.query_time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  size="mini"
                  @change="query">
              </el-date-picker>
            </div>
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>


        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              prop="date"
              label="日期">
            </el-table-column>
            <el-table-column
              prop="pay"
              label="代收总额">
            </el-table-column>
            <el-table-column
              prop="pay_count"
              label="代收笔数">
            </el-table-column>
            <el-table-column
              prop="pay_fee"
              label="代收手续费">
            </el-table-column>
            <el-table-column
              prop="cash"
              label="代付总额">
            </el-table-column>
            <el-table-column
              prop="cash_count"
              label="代付笔数">
            </el-table-column>
            <el-table-column
              prop="cash_fee"
              label="代付手续费">
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>
  </div>
</template>

<script>
import {transaction_summary_api} from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "MerchantSummary",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 20,
            total: 20
        },
      query_condition: {
        query_time: [], 
      },        
      collection_infos: [],
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    structure_data(type=1){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      let data = {
        start_time: start_time, 
        end_time: end_time,
      }
      if (type == 1) {
        data.page = this.page.page,
        data.page_size = this.page.page_size
      }
      return data
    },
    query(){        
        this.startLoading("查询中。。")      
        transaction_summary_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                item.date = this.$globalJs.picker_time(item.date, 1, 3)                
                item.pay = item.pay?item.pay:0
                item.pay_count = item.pay_count?item.pay_count:0
                item.cash = item.cash?item.cash:0
                item.cash_count = item.cash_count?item.cash_count:0
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.proccess{
    height: 30px;
    width: 50px;
    background-color: rgb(148, 95, 248);
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>


