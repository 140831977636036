<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">充值管理</span>
          <div style="float: right">
            <el-button @click="init_recharge('recharge')" type="primary" round>充值</el-button>
            <el-button @click="init_recharge()" type="primary" round>提现</el-button>
          </div>
        </div>

        <div style="display: flex; margin: 10px 0;font-size: 12px;">
            <div style="width: 460px; margin-right: 20px;">
              <span>提交时间：</span>
              <el-date-picker
                v-model="query_condition.query_time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini">
              </el-date-picker>
            </div>
            <div style="display: flex; line-height: 30px;margin-right: 20px;">
                <div>状态：</div>
                    <el-select v-model="query_condition.state" placeholder="请选择" style="width: 80px" size="mini">
                    <el-option
                        v-for="item in state_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>                
            </div>
            <div style="display: flex; line-height: 30px; margin-right: 20px;">
              <span style="width: 63px;">订单号：</span>
              <el-input v-model="query_condition.order_no" placeholder="请输入订单号" size="mini"></el-input>
            </div>     
            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
            <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
        </div>


        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }"
            :row-class-name="success_class">
            <el-table-column
              prop="create_time"
              label="发起时间"
              width="132">
            </el-table-column>
            <el-table-column
              prop="processing_time"
              label="处理时间"
              width="132">
            </el-table-column>
            <el-table-column
              label="类型"
              width="60">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.type">充值</div>
                <div class='proccess' v-else>提现</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="order_no"
              label="订单号"
              width="320">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="金额"
              width="200">
            </el-table-column>
            <el-table-column
              prop="add"
              label="地址"
              width="500">
            </el-table-column>
            <el-table-column
              label="状态"
              width="60">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">成功</div>
                <div class='proccess' v-if="scope.row.state == 0">处理中</div>
                 <div class='fail' v-if="scope.row.state == 2">驳回</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注">
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

        <el-dialog
        title="充值"
        :close-on-click-modal="false"
        :visible.sync="recharge_dialog"
        width="25%"
        >
        <!--      内容主体区域-->
        <el-form
            :model="edit_infos"
            :rules="InputRules"
            ref="recharge"
            label-width="80px"
            class="form_regist"
            >   
            <!-- <el-col :span="18">
                <el-form-item label="选择钱包">
                <el-select v-model="edit_infos.wallet_add" placeholder="请选择" style="width: 100%">
                    <el-option
                    v-for="item in wallet_infos"
                    :key="item.wallet_add"
                    :label="item.wallet_name"
                    :value="item.wallet_add">
                    </el-option>
                </el-select>
                </el-form-item>
            </el-col> -->
            <el-col :span="18">
                <el-form-item label="充值金额" prop="amount">
                <el-input
                    v-model="edit_infos.amount"
                    placeholder="请输入充值金额"
                    style="width: 100%"
                    maxlength="12"
                    >
                </el-input>
                </el-form-item>
            </el-col>
        </el-form>
        <!--      底部区域-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog">取 消</el-button>
            <el-button type="primary" @click="commit_recharge('recharge')"
            >确 定</el-button
            >
        </span>
        </el-dialog>   

        <el-dialog
        title="提现申请"
        :close-on-click-modal="false"
        :visible.sync="wd_dialog"
        width="25%"
        >
      
        <el-form
            :model="edit_infos"
            :rules="InputRules"
            ref="recharge"
            label-width="80px"
            class="form_regist">   

            <el-col :span="18">
                <el-form-item label="可用余额">
                <span style="color: blue">{{balance}}</span>
                </el-form-item>
            </el-col>
            <el-col :span="18">
                <el-form-item label="提现金额" prop="amount">
                <el-input
                    v-model="edit_infos.amount"
                    placeholder="请输入提现金额"
                    style="width: 100%"
                    maxlength="12"
                    >
                </el-input>
                </el-form-item>
            </el-col>

            <el-col :span="18">
                <el-form-item label="提现地址">
                <el-input
                    v-model="edit_infos.add"
                    placeholder="请输入提现地址"
                    style="width: 100%"
                    >
                </el-input>
                </el-form-item>
            </el-col>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog">取 消</el-button>
            <el-button type="primary" @click="commit_recharge('wd')"
            >确 定</el-button
            >
        </span>
        </el-dialog>   

  </div>
</template>

<script>
import { mer_recharge_api, merchant_info_api, submit_recharge_api } from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "RechargeManage",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '成功'
        },
        {
          value: 0,
          label: '处理中'
        },
        ],
      query_condition: {
        query_time: [], 
        state: "",
        order_no: '',
      },        
      collection_infos: [],
      recharge_dialog: false,
      wd_dialog: false,
      wallet_amount: '',
      edit_infos: {},
      balance: 0,
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      return {
        start_time: start_time, 
        end_time: end_time,
        state: this.query_condition.state,
        order_no:  this.query_condition.order_no,
        page: this.page.page,
        page_size: this.page.page_size
      }
    },

    query(){        
        this.startLoading("查询中。。")      
        mer_recharge_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1)
                item.processing_time = this.$globalJs.picker_time(item.processing_time, 1)
                if (item.amount < 0) {
                  item.type = 0
                  item.amount = -item.amount
                }else item.type = 1
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    reset_condition(){
      this.query_condition = {
        query_time: [], 
        state: "",
        order_no: '',
      }
      this.page.page = 1
      this.query()
    },
    success_class({row}){
        if (row.state === '处理中') return 'proccess-row'
        return ''
    },
    init_recharge(type){
        // this.startLoading('获取钱包数据中。。')
        // const data = {merchant_ab: window.sessionStorage.getItem('role')}
        // get_wallet_api(data).then((res)=>{
        // this.endLoading()
        // if (res.code === 200) {
        //     this.wallet_infos = res.data
        //     if (this.wallet_infos.length > 0){
        //       if (type === 'recharge') this.recharge_dialog = true
        //       else this.wd_dialog = true
        //     }else this.$message.warning('没有相关钱包，请联系管理员添加')
        // }        
        // else this.$message.error(res.message)
        // })   
        if (type === 'recharge') this.recharge_dialog = true
        else {
          merchant_info_api().then((res)=>{
          this.endLoading()
          if (res.code === 200) {
            this.balance = res.data.balance
            this.wd_dialog = true
          }else this.$message.error('获取数据失败，请重新获取')
        })
      }
    },
    commit_recharge(type){
        this.$refs.recharge.validate((valid) => {
            if (!valid) return
            if (!this.edit_infos.add && type == 'wd') {
              this.$message.error('请输入提现地址')
              return
            }
            if (this.edit_infos.amount <= 0) {
              this.$message.error('金额必须大于0')
              return
            }            
            if (type === 'wd' && this.edit_infos.amount > this.balance){
                this.$message.error('可用余额不足')
                return
              }
            this.startLoading('插入数据中')
            this.edit_infos.type = type
            submit_recharge_api(this.edit_infos).then((res)=>{
                this.endLoading()
                if (res.code === 200){
                    this.$message.success('修改成功')
                    this.close_dialog()
                    this.query()                    
                }else if (res.code === 409) {
                  this.amount = res.data
                  this.balance = res.data
                  this.$message.error('余额变动，可用余额不足')
                }
                else this.$message.error(res.message)
            })        
        })
    },

    close_dialog(){
        this.edit_infos = {}
        this.recharge_dialog = false
        this.wd_dialog = false
        this.wallet_amount = ''
    },
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;  
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.proccess{
    height: 30px;
    width: 50px;
    background-color: rgb(148, 95, 248);
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.fail{
    height: 30px;
    width: 50px;
    background-color: red;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>



