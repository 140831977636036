<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">人员管理</span>
          <div style="float: right">
            <el-button @click="add_business" type="primary" round>新增人员</el-button>
          </div>
        </div>

        <div class="table_list">
          <el-table
            :data="business_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              prop="user_name"
              label="用户名">
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="创建时间">
            </el-table-column>            
            <el-table-column
              prop="login_time"
              label="最后登录时间时间">
            </el-table-column> 
            <el-table-column
              label="状态"
              width="60">
              <template slot-scope="scope">
                <div class='enable' v-if="scope.row.state == 1">正常</div>
                <div class='disable' v-if="scope.row.state == 0">禁用</div>
              </template>
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button size="mini" @click="edit_user(scope.row, 'enable')" v-if="scope.row.state == 0">启用</el-button>
                <el-button size="mini" @click="edit_user(scope.row, 'disable')" v-if="scope.row.state == 1">禁用</el-button>
                <el-button size="mini" @click="reset_pw(scope.row)" v-if="scope.row.state == 1">重置密码</el-button>
                <el-button size="mini" @click="create_qrcode(scope.row)">查看谷歌秘钥</el-button>
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

        <el-dialog
        title="新增运营"
        :close-on-click-modal="false"
        :visible.sync="business_dialog"
        width="25%"
        >
        <!--      内容主体区域-->
        <el-form
            :model="edit_infos"
            :rules="InputRules"
            ref="add_business"
            label-width="100px"
            class="form_regist"
            >   
            <el-col :span="18">
                <el-form-item label="运营账号" prop="user_name">
                <el-input
                    v-model="edit_infos.user_name"
                    placeholder="请输入运营账号"
                    style="width: 100%"
                    maxlength="16"
                    >
                </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="18">
                <el-form-item label="谷歌验证码" prop="gtoken">
                <el-input
                    v-model="edit_infos.gtoken"
                    placeholder="请输入谷歌验证码"
                    style="width: 100%"
                    maxlength="6"
                    >
                </el-input>
                </el-form-item>
            </el-col>
            <div>提示：默认登录密码为 <span style="color: red">123456</span> </div>
        </el-form>
        <!--      底部区域-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog">取 消</el-button>
            <el-button type="primary" @click="commit_business"
            >确 定</el-button
            >
        </span>
        </el-dialog>   

        <el-dialog
        title="Google秘钥"
        :close-on-click-modal="false"
        :visible.sync="view_gtoken_dialog"
        width="25%"
        @close="close_qrcode"
            >
            <!--      内容主体区域-->
            <el-form
                label-width="120px"
                class="form_regist"
            >
                <div>Google秘钥：<span style="color: #24a9f6">{{edit_infos.gtoken}}</span></div>
                <div id="qrcode" style="padding-top: 30px"></div>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
                <el-button @click="close_qrcode">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
        title="提示"
        :close-on-click-modal="false"
        :visible.sync="gtoken_dialog"
        width="25%"
        >
          <!--      内容主体区域-->
          <el-form
              :model="edit_infos"
              :rules="InputRules"
              ref="gtoken"
              label-width="100px"
              class="form_regist"
              >               
              <el-form-item label="谷歌验证码" prop="gtoken">
                <el-input
                    v-model="edit_infos.gtoken"
                    placeholder="请输入谷歌验证码"
                    style="width: 100%"
                    maxlength="6"
                    >
                </el-input>
              </el-form-item>
          </el-form>
          <!--      底部区域-->
          <span slot="footer" class="dialog-footer">
              <el-button @click="close_dialog">取 消</el-button>
              <el-button type="primary" @click="commit_reset_pw"
              >确 定</el-button
              >
          </span>
        </el-dialog> 

        

  </div>
</template>

<script>
import { register_api, disable_account_api, reset_pw_api, get_children_account_api } from "../network/api/user";
import paging from "../components/pagenation";
import QRCode from "qrcodejs2";

export default {  
  name: "BusinessManage",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },   
      business_infos: [],
      business_dialog: false,      
      view_gtoken_dialog: false,
      gtoken_dialog: false,
      edit_infos: {},
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    query(){        
        this.startLoading("查询中。。")      
        const data = {page: this.page.page, page_size: this.page.page_size}
        get_children_account_api(data).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.business_infos = res.data.datas
                this.business_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1)
                item.login_time = this.$globalJs.picker_time(item.login_time, 1)
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    add_business(){
        this.business_dialog = true
    },
    commit_business(){
        this.$refs.add_business.validate((valid) => {
            if (!valid) return
            this.startLoading('插入数据中。。')
            const data = {
                user_name: this.edit_infos.user_name,
                role: window.sessionStorage.getItem('role'),
                password: this.$md5('123456'),
                gtoken: this.edit_infos.gtoken
            }
            register_api(data).then((res)=>{
                this.endLoading()
                if (res.code === 201) {
                    this.$message.success('添加成功')
                    this.query()
                    this.close_dialog()
                }else this.$message.error(res.message)
            })
        })
    },
    edit_user(row, type){
        this.startLoading('修改数据中。。')
        const data = {
            user_name: row.user_name,
            type: type
        }
        disable_account_api(data).then((res)=>{
            this.endLoading()
            if (res.code===200) {
                this.$message.success('修改成功')
                row.state = row.state? 0: 1
                }else this.$message.error(res.message)
        })
    },
    create_qrcode(row){        
        this.edit_infos.gtoken = row.gtoken
        this.view_gtoken_dialog = true
        this.$nextTick(() =>{
            new QRCode('qrcode', {
                text: "otpauth://totp/" + row.user_name +"?secret=" + row.gtoken, // 需要转换为二维码的内容
                width: 200,
                height: 200,
                colorDark: "black", //#000000为黑色
                colorLight: "white",
            });
        });
    },
    close_qrcode(){
        this.close_dialog()
        document.getElementById('qrcode').innerHTML = ''
    },

    reset_pw(row){
        this.row = row
        this.gtoken_dialog = true
    },
    commit_reset_pw(){
        this.startLoading('插入数据中。。')
        const data ={
        type: 'other',
        operator: this.row.user_name,
        password: this.$md5('123456'),
        gtoken: this.edit_infos.gtoken
        }
        reset_pw_api(data).then((res)=>{
        this.endLoading()
        if (res.code === 200) {
            this.$message.success('重置成功，默认密码为 123456')
            this.close_dialog()
        }
        else this.$message.error(res.message)
        })

    },
    close_dialog(){
        this.business_dialog = false
        this.view_gtoken_dialog = false
        this.gtoken_dialog = false
        this.edit_infos = {}
    },       
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.enable{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}

.disable{
    height: 30px;
    width: 50px;
    background-color: red;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>


