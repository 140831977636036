
import axios from 'axios';
import {Message, MessageBox} from "element-ui";
import _config from "./baseurl"
  
  const request = axios.create({    
    baseURL: _config.base_url,
    timeout: 60000,//请求超时
    withCredentials: true
  });
  //请求拦截器
  request.interceptors.request.use(config => {
      if (['/user/login', '/user/logout'].indexOf(config.url) === -1) {
        const username = sessionStorage.getItem('user_name')
        const role = sessionStorage.getItem('role')
        const session = sessionStorage.getItem('session')
        if (session) {
          config.headers.session = session
          config.headers.role = role
          config.headers.username = username
        }
      }
      return config;
    },
    err => {
      Message.error('对方服务器没有响应')
    });
  //响应拦截器
request.interceptors.response.use(res => {
    if (res.data.code === 401) {
      MessageBox.alert('登录超时！', '提示', { type: 'error' })
      window.sessionStorage.clear()      
      window.location.href = "/";
    }
    if (res.data.code === 502) {
      MessageBox.alert('后台系统崩溃请联系管理员！', '提示', { type: 'error' })
    }
    if (res.data.code === 403) {
      MessageBox.alert('文件类型错误！', '提示', { type: 'error' })
    }
      return res.data;
    }, err => {
      if (err.stack.indexOf('timeout') >-1) Message.error('对方服务器没有响应')
      else if (err.stack.indexOf('Network Error') >-1) Message.error('网络连接失败')
      console.log(err)
   });   
  export default request

  export function get_res(url, data) {
    return request({
      url: url,
      method: 'get',
      params: data,
    })
  }

  export function post_res(url, data) {
    console.log(url, data)
    // return request({
    //   url: url,
    //   method: 'post',
    //   json: data,
    // })
  }


