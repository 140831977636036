<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">服务商管理</span>
          <div style="float: right">
            <el-button @click="add_service" type="primary" round>新增服务商</el-button>
          </div>          
        </div>

        <div style="display: flex; margin: 10px 0;font-size: 12px">
            <div style="width: 460px;margin-right: 20px;" >
              <span>起止日期：</span>
              <el-date-picker
                v-model="query_condition.query_time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini">
              </el-date-picker>
            </div>
            <div style="display: flex; line-height: 30px;margin-right: 20px;">
                <div>状态：</div>
                    <el-select v-model="query_condition.state" placeholder="请选择" style="width: 100px" size="mini">
                    <el-option
                        v-for="item in state_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>                
            </div>
            <div style="display: flex; line-height: 30px;margin-right: 20px;">
              <span style="width:61px;">服务商：</span>
              <el-input v-model="query_condition._name" placeholder="请输入通道名称" size="mini"></el-input>
            </div>               
            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
            <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>

        <div class="table_list">
          <el-table
            :data="service_info"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
            label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              prop="name"
              label="服务商名称"
              width="120">
            </el-table-column>
            <el-table-column
              prop="balance"
              label="可用余额"
              width="120">
            </el-table-column>
            <el-table-column
              prop="frozen"
              label="已提现金额"
              width="120">
            </el-table-column>
            <el-table-column
              label="状态"
              width="60">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">正常</div>
                <div class='fail' v-if="scope.row.state == 0">停用</div>
              </template>
            </el-table-column>  
            <el-table-column
              prop="create_time"
              label="加入时间"
              width="140">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button size="mini" @click="service_manage(scope.row, 'enable')" v-if="scope.row.state == 0">开启</el-button>
                <el-button size="mini" @click="service_manage(scope.row, 'disable')" v-if="scope.row.state == 1">关闭</el-button>
                <el-button size="mini" @click="frozen(scope.row)" v-if="scope.row.state == 1">扣除提现金额</el-button>
                <el-button size="mini" @click="get_gtoken(scope.row)" v-if="scope.row.state == 1">查看秘钥</el-button>
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

      <el-dialog
        title="新增服务商"
        :close-on-click-modal="false"
        :visible.sync="add_service_dialog"
        width="25%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="new_service_info"
        :rules="InputRules"
        ref="add_service"
        label-width="120px"
        class="form_regist"
      >
        <el-form-item label="服务商名称" prop="name">
          <el-input
            v-model="new_service_info.name"
            placeholder="请输入服务商名称"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="服务商缩写" prop="name_ab">
          <el-input
            v-model="new_service_info.name_ab"
            placeholder="请输入服务商缩写"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        <div>提示: 服务商登录名为服务商缩写，默认密码为<span style="color: red">123456</span></div>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="add_service_dialog = false">取 消</el-button>
        <el-button type="primary" @click="commit_service"
          >确 定</el-button
        >
      </span>
    </el-dialog>    

    <el-dialog
        title="扣除提现金额"
        :close-on-click-modal="false"
        :visible.sync="frozen_dialog"
        width="25%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="new_service_info"
        :rules="InputRules"
        ref="frozen_amount"
        label-width="120px"
        class="form_regist"
      >
        <el-form-item label="提现金额" prop="amount">
          <el-input
            v-model="new_service_info.amount"
            placeholder="请输入提现金额"
            style="width: 100%"
            maxlength="12"
            @keyup.enter.native="commit_frozen"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="frozen_dialog = false">取 消</el-button>
        <el-button type="primary" @click="commit_frozen"
          >确 定</el-button
        >
      </span>
    </el-dialog>   

    <el-dialog
        title="Google秘钥"
        :close-on-click-modal="false"
        :visible.sync="gtoken_dialog"
        width="25%"
        @close="close_qrcode"
      >
      <!--      内容主体区域-->
      <el-form
        label-width="120px"
        class="form_regist"
      >
        <div>Google秘钥：<span style="color: #24a9f6">{{edit_infos.gtoken}}</span></div>
        <div id="qrcode" style="padding-top: 30px"></div>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_qrcode">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { service_query_api, reg_service_api, service_manage_api } from "../network/api/merchant";
import { get_gtoken_api } from '../network/api/user'
import paging from "../components/pagenation";
import QRCode from "qrcodejs2";

export default {  
  name: "serivce_manage",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '正常'
        },
        {
          value: 0,
          label: '禁用'
        },
        ],
      query_condition: {
        query_time: [], 
        state: "",
        _name: '',
      },        
      user_name: '',
      service_info: [],
      add_service_dialog: false,
      gtoken_dialog: false,
      new_service_info: {},
      service_info: [],
      frozen_dialog: false,
      edit_infos: {}
    }
  },
  created() {
    this.query()
  },
  methods:{
  currentpage(val){
      this.page.page = val
      this.query()
    },
  condition_query(){
    this.page.page = 1
    this.query()
  },
  structure_data(){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      return {
        state: this.query_condition.state,
        start_time: start_time,
        end_time: end_time,
        _name: this.query_condition.name,
        page: this.page.page,
        page_size: this.page.page_size
      }
    },
  query(){
      this.startLoading("查询中。。")      
      service_query_api(this.structure_data()).then((res) => {       
          this.endLoading() 
          if (res.code === 200) {
            this.service_info = res.data.datas
            this.service_info.forEach(item => {
            item.create_time = this.$globalJs.picker_time(item.create_time, 1)
            })
            this.page.total = res.data.total
          }else{
            this.$message.error(res.message)
          }
    })
    },   
  reset_condition(){
      this.query_condition = {
        query_time: [], 
        state: "",
        _name: '',
      }
      this.page.page = 1
      this.query()
    },
  add_service(){
    this.new_service_info = {}
    this.add_service_dialog = true
    },
  commit_service(){
    this.$refs.add_service.validate((valid) => {
        if (!valid) return;
        this.startLoading('插入数据中。。')
        const data ={
          service_name: this.new_service_info.name,
          service_ab: this.new_service_info.name_ab
        }
        reg_service_api(data).then((res) => {
          this.endLoading()
        if (res.code === 200){
            this.add_service_dialog = false;
            this.$message.success('添加成功')           
            this.query() 
        } else this.$message.error(res.data)
        })
        
    });
    },
  get_gtoken(row){
    this.row = row
    this.startLoading('获取中。。')
    const data = {user_name: row.ab}
    get_gtoken_api(data).then((res)=>{
      this.endLoading()
      if (res.code===200){
        this.edit_infos.gtoken = res.data.gtoken
        this.gtoken_dialog = true
        this.create_qrcode(row.ab)
      }else this.$message.error('获取秘钥失败' + res.message)
    })    
  },
  create_qrcode(user_name){
    this.$nextTick(() =>{
    new QRCode('qrcode', {
        text: "otpauth://totp/" + user_name +"?secret=" + this.edit_infos.gtoken, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "black", //#000000为黑色
        colorLight: "white",
      });
  });
  },
  close_qrcode(){
    this.gtoken_dialog = false; 
    this.edit_infos = {}
    document.getElementById('qrcode').innerHTML = ''
  },
  edit_row(row, type, params){
    if (type === 'disable') row.state = 0
    else if (type === 'enable') row.state = 1
    else if (type === 'frozen') {
      row.balance = row.balance - params
      row.frozen_amount = row.frozen_amount + params
      }
    else this.$message.error('操作错误！！')
  },
  service_manage(row, type, params=null){
    this.startLoading('插入数据中。。')
    const data = {service_ab: row.ab, type: type}
    if (params) data['data'] = params
    service_manage_api(data).then((res)=>{
      this.endLoading()
      if (res.code === 200){
            this.$message.success('修改成功')           
            this.frozen_dialog = false
            this.edit_row(row, type, params)
            this.frozen_state = false
          } else this.$message.error(res.message)
      this.frozen_state = false
    })    
  },  
  frozen(row){
    this.row = row
    this.frozen_dialog = true
  },
  commit_frozen(){
    this.$refs.frozen_amount.validate((valid) => {      
                if (!valid) return;                
                if (this.frozen_state) return 
        const amount = parseInt(this.new_service_info.amount)
        if (parseInt(this.row.balance) >= amount) {
          this.frozen_state = true
          this.service_manage(this.row, 'frozen', amount)          
        }else this.$message.error('可用余额不足')
    })
  },
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>

