<template>
  <div class="pagenation" v-if="web_width > 600">
     <el-pagination
     background
     @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.page"
      :page-size="page.page_size"
      :page-sizes="[10, 100, 200]"
      layout="total, prev, pager, next, jumper"
      :total="page.total">
    </el-pagination>
  </div>

  <div class="pagenation" v-else>
     <el-pagination
     background
     @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.page"
      :page-size="page.page_size"
      small
      layout="total, prev, pager, next"
      :total="page.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
    name: "PagiNation",
    props: {
        page: {
            type: Object
        },
    },
    data() {
        return {
          web_width: document.body.clientWidth
        }
    },
    mounted() {
    },
    methods: {
        // 监听当前页面
        handleCurrentChange(newCurrent) {
        this.$emit('CurrentChange', newCurrent)
        },
        handleSizeChange(newSize) {
          this.$emit('sizeChange', newSize)
        },
   }
};
</script>

<style scoped>
.pagenation {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
</style>
