<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 30px; line-height: 30px; padding: 0 10px; margin-bottom: 3px;" id="header">
          <span style="font-size: 16px; color: white">代付查询</span>
        </div>
        <div style="display: flex; line-height: 30px;margin: 0 20px;">
          <div style="font-size: 12px">订单状态：</div>
            <el-select v-model="query_condition.state" placeholder="请选择" style="width: 120px" size="mini">
            <el-option
                v-for="item in state_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
            </el-select>                
        </div>
        <div style="display: flex; line-height: 30px; margin: 0 20px;">
          <span style="font-size: 12px">订单号：</span>
          <el-input v-model="query_condition.order_no" placeholder="请输入订单号" size="mini" style="width: 214px"></el-input>
        </div> 

        <div style="display: flex; justify-content: left; width: 100%; font-size: 22px; color: red">
            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
            <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>


        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              label="发起时间"
              width="84">
              <template slot-scope="scope">
                <div>{{ scope.row.create_time[0]}}</div>
                <div>{{ scope.row.create_time[1]}}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="处理时间"
              width="84">
              <template slot-scope="scope">
                <div>{{ scope.row.processing_time[0]}}</div>
                <div>{{ scope.row.processing_time[1]}}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="merchant_order_no"
              label="订单号"
              width="200">
            </el-table-column>            
            <el-table-column
              prop="account_name"
              label="户名">
            </el-table-column>
            <el-table-column
              prop="card_no"
              label="卡号"
              width="160">
            </el-table-column>
            <el-table-column
              prop="bank"
              label="银行">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="付款金额">
            </el-table-column>
            <el-table-column
              prop="channel_charge"
              label="手续费">
            </el-table-column>
            <el-table-column
              prop="real_amount"
              label="结算金额">
            </el-table-column>
            <el-table-column
              label="备注"
              width="80">
              <template slot-scope="scope">                  
                  <el-popover
                    placement="top-start"
                    width="140"
                    trigger="hover"
                    effect="light"  
                    :content=scope.row.remark>
                    <span slot="reference">{{scope.row.remark | ellipsis }}</span>
                </el-popover> 
              </template>
            </el-table-column>
            <el-table-column
              label="交易状态"
              width="68">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">成功</div>
                <div class='proccess' v-if="scope.row.state == 0">处理中</div>
                <div class='fail' v-if="scope.row.state == 2">失败</div>
              </template>
            </el-table-column>            
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                  <div>
                      <el-button size="mini" @click="notice_again(scope.row)" v-if="scope.row.state != 0">补发通知</el-button>
                  </div>               
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

        <el-dialog
          title="提示"
          :close-on-click-modal="false"
          :visible.sync="resend_dialog"
          width="25%"
          >
          <!--      内容主体区域-->
          <el-form
              :model="edit_infos"
              :rules="InputRules"
              ref="resend"
              label-width="120px"
              class="form_regist"
          >
              <el-form-item label="谷歌验证码" prop="gtoken">
              <el-input
                  v-model="edit_infos.gtoken"
                  placeholder="请输入谷歌验证码"
                  style="width: 100%"
                  maxlength="6"
              >
              </el-input>
              </el-form-item>
          </el-form>
          <!--      底部区域-->
          <span slot="footer" class="dialog-footer">
              <el-button @click="close_dialog">取 消</el-button>
              <el-button type="primary" @click="commit_resend"
              >确 定</el-button
              >
          </span>
        </el-dialog>  

  </div>
</template>

<script>
import { merchant_cash_api, resend_notice_api, cal_query_api } from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "H5MerchantPay",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '成功'
        },
        {
          value: 2,
          label: '失败'
        },
        {
          value: 0,
          label: '处理中'
        },
        ],
      query_condition: {
        query_time: [], 
        state: "",
        account_name: '',
        order_no: '',
        card_no: '',
        bank: '',
      },        
      collection_infos: [],
      resend_dialog: false,
      edit_infos: {},
      all_info: {
        amount: 0,
        times: 0,
        charge: 0
      },
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(type=1){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      let data = {
        start_time: start_time, 
        end_time: end_time,
        state: this.query_condition.state,
        merchant_order_no:  this.query_condition.order_no,
        notice: this.query_condition.notice,
        type: 'extend'
      }
      if (type == 1) {
        data.page = this.page.page,
        data.page_size = this.page.page_size,
        data.type = 'query'
      }
      return data
    },
    query(){        
        this.all_info = {amount: 0, times: 0, charge: 0}
        this.startLoading("查询中。。")      
        merchant_cash_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1).split(" ")
                item.processing_time = this.$globalJs.picker_time(item.processing_time, 1).split(" ")
                item.real_amount = item.amount + item.channel_charge
                item.remark = item.remark.includes('手动')? '手动': row.remark
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    reset_condition(){
      this.query_condition = {
        query_time: [], 
        state: "",
        account_name: '',
        order_no: '',
        card_no: '',
        bank: '',
      }
      this.page.page = 1
      this.query()
    },
    notice_again(row){
        this.row = row
        this.resend_dialog = true
    },
    commit_resend(){
        const data = {
            merchant_order_no: this.row.merchant_order_no,
            amount: this.row.amount,
            realamount: this.row.real_amount,
            gtoken: this.edit_infos.gtoken,
            callback_url: this.row.callback_url,
            type: 'cash',
            merchant_no: window.sessionStorage.getItem('role'),
            order_state: this.row.state == '1'? '成功': '失败',
            msg: this.row.remark
        }
        resend_notice_api(data).then((res)=>{
            if (res.code === 200) {
                this.$message.success('发送成功')
                this.close_dialog()
                }
            else this.$message.error(res.message)
        })
    },
    close_dialog(){
        this.resend_dialog = false
        this.edit_infos = {}
    },
    get_sum(){
      this.startLoading("查询中。。")   
      let data = this.structure_data(0)
      data.type = 'cash'
      cal_query_api(data).then((res)=>{
        this.endLoading()
        this.all_info = res.data
      })
    },
},  
filters: {
    //文字数超出时，超出部分使用...
  ellipsis(value) {
      if (!value) return ''
      if (value.length > 10) {
          return value.slice(0, 8) + '...'
      }
      return value
  }
}
}
</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.proccess{
    height: 30px;
    width: 50px;
    background-color: rgb(148, 95, 248);
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.fail{
    height: 30px;
    width: 50px;
    background-color: red;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>


