<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">通道管理</span>
          <div style="float: right">
            <el-button @click="add_channel" type="primary" round>新增通道</el-button>
          </div>          
        </div>

        <div style="display: flex; margin: 10px 0;font-size: 12px;">
            <div style="width: 460px;margin-right: 20px;" >
              <span>起止日期：</span>
              <el-date-picker
                v-model="query_condition.query_time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini">
              </el-date-picker>
            </div>
            <div style="display: flex; line-height: 30px;margin-right: 20px;">
                <div>状态：</div>
                    <el-select v-model="query_condition.state" placeholder="请选择" style="width: 120px" size="mini">
                    <el-option
                        v-for="item in state_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>                
            </div>
            <div style="display: flex; line-height: 30px;margin-right: 20px;">
              <span style="width: 80px;">通道名称：</span>
              <el-input v-model="query_condition.name" placeholder="请输入通道名称" size="mini"></el-input>
            </div>               
            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>
            <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>

        <div class="table_list">
          <el-table
            :data="channel_info"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
            label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              prop="name"
              label="通道名称"
              width="120">
            </el-table-column>
            <el-table-column
              prop="ab"
              label="通道缩写"
              width="120">
            </el-table-column>
            <el-table-column
              label="状态"
              width="60">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">正常</div>
                <div class='fail' v-if="scope.row.state == 0">停用</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="加入时间"
              width="140">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button size="mini" @click="channel_manage(scope.row, 'enable')" v-if="scope.row.state == 0">开启</el-button>
                <el-button size="mini" @click="channel_manage(scope.row, 'disable')" v-if="scope.row.state == 1">关闭</el-button>
                <el-button size="mini" @click="edit_encryption(scope.row)" v-if="scope.row.state == 1">修改通道参数</el-button>
                <!-- <el-button size="mini" @click="edit_recharge(scope.row)" v-if="scope.row.state == 1">修改手续费</el-button> -->
                <el-button size="mini" @click="join_merchant(scope.row)" v-if="scope.row.state == 1">查看接入商户</el-button>
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

      <el-dialog
        title="新增通道"
        :close-on-click-modal="false"
        :visible.sync="add_channel_dialog"
        width="80%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="new_channel_info"
        :rules="InputRules"
        ref="add_channel"
        label-width="120px"
        class="form_regist"
      > 
        <el-col :span="20">
          <el-form-item label="支付方式">
            <el-select v-model="payment" placeholder="请选择"  value-key="name" @change="change">
                    <el-option
                        v-for="item in payment_options"
                        :key="item.name"
                        :label="item.name"
                        :value="item">
                    </el-option>
                    </el-select> 
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="通道名称" prop="name">
            <el-input
              v-model="new_channel_info.name"
              placeholder="请输入通道名称"            
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="商户号" prop="merchant_no">
            <el-input
              v-model="new_channel_info.merchant_no"
              placeholder="请输入商户号"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col> 
        <el-col :span="20">
          <el-form-item label="请求秘钥" prop="secret_key">
            <el-input
              v-model="new_channel_info.secret_key"
              placeholder="请输入请求秘钥"
              type="textarea"
              width="120"
              show-word-limit
              resize="none"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="验证秘钥" prop="plat_secret_key">
            <el-input
              v-model="new_channel_info.plat_secret_key"
              placeholder="请输入验证秘钥"
              type="textarea"
              width="120"
              show-word-limit
              resize="none"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="app_id">
            <el-input
              v-model="new_channel_info.app_id"
              placeholder="请输入app_id"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog">取 消</el-button>
        <el-button type="primary" @click="commit_add_channel"
          >确 定</el-button
        >
      </span>
    </el-dialog>    

    <el-dialog
        title="修改通道参数"
        :close-on-click-modal="false"
        :visible.sync="secret_dialog"
        width="80%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_infos"
        :rules="InputRules"
        ref="edit_encryption"
        label-width="120px"
        class="form_regist"    
      >
        
        <el-col :span="20">
          <el-form-item label="商户号" prop="merchant_no">
            <el-input
              v-model="edit_infos.merchant_no"
              placeholder="请输入商户号"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col> 
        <el-col :span="20">
          <el-form-item label="请求秘钥" prop="secret_key">
            <el-input
              v-model="edit_infos.secret_key"
              placeholder="请输入请求秘钥"
              type="textarea"
              width="120"
              show-word-limit
              resize="none"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="验证秘钥" prop="plat_secret_key">
            <el-input
              v-model="edit_infos.plat_secret_key"
              placeholder="请输入验证秘钥"
              type="textarea"
              width="120"
              show-word-limit
              resize="none"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="app_id">
            <el-input
              v-model="edit_infos.app_id"
              placeholder="请输入app_id"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="20">
          <el-form-item label="api_add">
            <el-input
              v-model="edit_infos.api_add"
              placeholder="请输入api_add"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
     
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog">取 消</el-button>
        <el-button type="primary" @click="commit_encryption"
          >确 定</el-button
        >
      </span>
    </el-dialog>   

    <!-- <el-dialog
        title="修改通道手续费"
        :close-on-click-modal="false"
        :visible.sync="recharge_dialog"
        width="25%"
      >

      <el-form
        :model="edit_infos"
        :rules="InputRules"
        ref="recharge"
        label-width="120px"
        class="form_regist"
      > 
        <el-col :span="20">
          <el-form-item label="代收手续费" prop="recharge_per">
            <el-input
              v-model="edit_infos.recharge_per"
              placeholder="请输入代收手续费"
              type="number"
              style="width: 100%"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代收单笔手续费" prop="recharge_single">
            <el-input
              v-model="edit_infos.recharge_single"
              placeholder="请输入代收单笔手续费"
              type="number"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付手续费" prop="wd_per">
            <el-input
              v-model="edit_infos.wd_per"
              placeholder="请输入代付手续费"
              type="number"
              style="width: 100%"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付单笔手续费" prop="wd_single">
            <el-input
              v-model="edit_infos.wd_single"
              placeholder="请输入代付单笔手续费"
              type="number"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog">取 消</el-button>
        <el-button type="primary" @click="commit_recharge"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->

    <el-dialog
        title="接入商户"
        :close-on-click-modal="false"
        :visible.sync="join_merchant_dialog"
        width="80%"
      >
      <!--      内容主体区域-->
      <el-table
      :data="merchant_infos"
      style="width: 100%">
        <el-table-column
          prop="name"
          label="商户名称">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="接入时间">
        </el-table-column>
        <el-table-column
          prop="recharge_per"
          label="代收手续费">
        </el-table-column>
        <el-table-column
          prop="recharge_single"
          label="代收单笔手续费">
        </el-table-column>
        <el-table-column
          prop="wd_per"
          label="代付手续费">
        </el-table-column>
        <el-table-column
          prop="wd_single"
          label="代付单笔手续费">
        </el-table-column>
      </el-table>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog">确 定</el-button>        
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { reg_channel_api,  channel_query_api, channel_manage_api, join_merchant_api, pay_method_api} from "../network/api/channel";
import paging from "../components/pagenation";

export default {  
  name: "channel_manage",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '正常'
        },
        {
          value: 0,
          label: '禁用'
        },
        ],
      payment_options: [],
      query_condition: {
        query_time: [],        
        state: "",
        name: '',
      },
      channel_info: [],    
      merchant_infos: [],
      new_channel_info: {},
      edit_infos:{},
      secret_key: '',
      plat_secret_key: '',
      add_channel_dialog: false,
      secret_dialog: false,
      recharge_dialog: false,
      join_merchant_dialog: false,
      row_infos: {
        recharge_single: 0,
        recharge_per: 0,
        wd_per: 0,
        wd_single: 0
      },      
      payment: {}
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      return {
        state: this.query_condition.state,
        start_time: start_time,
        end_time: end_time,
        _name: this.query_condition.name,
        page: this.page.page,
        page_size: this.page.page_size
      }
    },
    query(){
      this.startLoading("查询中。。")      
      channel_query_api(this.structure_data()).then((res) => {       
          this.endLoading() 
          if (res.code === 200) {
            this.channel_info = res.data.datas
            this.channel_info.forEach(item => {
            item.create_time = this.$globalJs.picker_time(item.create_time, 1)
            })
            this.page.total = res.data.total
          }else{
            this.$message.error(res.message)
          }
    })
    },   
    reset_condition(){
      this.query_condition = {
        query_time: [],        
        state: "",
        name: '',
      }
      this.page.page = 1
      this.query()
    },
    compare_value(data){
      for (let i=0; i< data.length; i++){
        if (this.edit_infos[data[i]] != this.row[data[i]]) return false      
      }
      return true
    },
    add_channel(){
      pay_method_api({type: 'all'}).then((res)=>{
        if (res.code===200){
          this.add_channel_dialog = true
          this.payment_options = res.data
        }
      })
      
    },
    commit_add_channel(){
            this.$refs.add_channel.validate((valid) => {
            if (!valid) return;
            this.new_channel_info.api_add = this.payment.api_add
            this.new_channel_info.call_method = this.payment.method
            this.new_channel_info.manual = this.payment.manual
            this.startLoading('插入数据中。。')
            reg_channel_api(this.new_channel_info).then((res)=>{
              this.endLoading()
              if (res.code === 200){
                this.$message.success('添加成功')
                this.query()
                this.close_dialog()
              }else{this.$message.error(res.message)}
            })            
            })
    },
    edit_row(row, type, params){
      if (type === 'disable') row.state = 0
      else if (type === 'enable') row.state = 1
      else if (type === 'white_list') row.IP = params
      else if (type === 'edit_enc') {
        const key = ['IP', 'api_add', 'merchant_no', 'plat_secret_key', 'secret_key']
        for (let i=0; i<key.length; i++){
          row[key[i]] = params[key[i]]
        }
      }
      else if (type === 'edit_recharge') {
        const key = ['recharge_per', 'recharge_single', 'wd_per', 'wd_single']
        for (let i=0; i<key.length; i++){
          row[key[i]] = params[key[i]]
        }
      }else this.$message.error('操作错误！！')
    },
    channel_manage(row, type, params=null){
      this.startLoading('插入数据中。。')
      const data = {ab: row.ab, type: type}
      if (params) data['data'] = params
      channel_manage_api(data).then((res)=>{
        this.endLoading()
        if (res.code === 200){
          this.$message.success('修改成功')   
          this.edit_row(row, type, params)
          this.close_dialog()
        }else this.$message.error(res.message)
      })
    },
    close_dialog(){
      this.add_channel_dialog = false
      this.secret_dialog = false
      this.recharge_dialog = false
      this.join_merchant_dialog = false
      this.new_channel_info = {}
      this.edit_infos = {}
      this.merchant_infos = []
    },
    edit_encryption(row){
      this.row = row
      this.edit_infos = {}
      this.secret_dialog = true
      this.$set(this.edit_infos, 'secret_key', row.secret_key)
      this.$set(this.edit_infos, 'plat_secret_key', row.plat_secret_key)
      this.$set(this.edit_infos, 'merchant_no', row.merchant_no)
      this.$set(this.edit_infos, 'app_id', row.app_id)  
      this.$set(this.edit_infos, 'api_add', row.api_add)  
    },
    commit_encryption(){
      this.$refs.edit_encryption.validate((valid) =>{
        if (!valid) return
        if (this.compare_value(['api_add', 'app_id', 'merchant_no', 'plat_secret_key', 'secret_key'])) this.$message.warning('无修改')
        else {
          this.channel_manage(this.row, 'edit_enc', this.edit_infos)          
      }
      })
    },
    edit_recharge(row){
      this.row = row
      this.$set(this.edit_infos, 'recharge_per', '' + row.recharge_per)
      this.$set(this.edit_infos, 'recharge_single', '' + row.recharge_single)
      this.$set(this.edit_infos, 'wd_per', '' + row.wd_per)
      this.$set(this.edit_infos, 'wd_single', '' + row.wd_single)
      this.recharge_dialog = true
    },
    commit_recharge(){
      this.$refs.recharge.validate((valid) => {
        if (!valid) return
        if (this.compare_value(['recharge_per', 'recharge_single', 'wd_per', 'wd_single'])) this.$message.warning('无修改')
        else {
          this.channel_manage(this.row, 'edit_recharge', this.edit_infos)          
      }
    })
  },
    join_merchant(row){
      this.startLoading('获取数据中。。')
      join_merchant_api({channel_ab: row.ab}).then((res)=>{
        this.endLoading()
        if (res.code === 200) {
          this.merchant_infos = res.data
          this.merchant_infos.forEach(item => {
            item.create_time = this.$globalJs.picker_time(item.create_time, 1)
            })
          this.join_merchant_dialog = true
        }else this.$message.error(res.message)
      })
    },
    change(data){      
    }
},
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.fail{
    height: 30px;
    width: 50px;
    background-color: red;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>

