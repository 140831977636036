<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 30px; line-height: 30px; padding: 0 10px; margin-bottom: 3px;" id="header">
          <span style="font-size: 16px; color: white">商户管理</span>
          <div style="float: right">
            <el-button @click="condition_query" type="primary" round size="mini">刷新</el-button>
            <el-button @click="add_merchant" type="primary" round size="mini">添加商户</el-button>
          </div>          
        </div>

        <div class="table_list">
          <el-table
            :data="merchant_info"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              prop="name"
              label="商户名称"
              width="46">
            </el-table-column>
            <el-table-column
              prop="channel"
              label="代收通道"
              width="46">
            </el-table-column>
            <el-table-column
              prop='ab'
              label="商户号"
              width="62">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="余额">
            </el-table-column>
            <!-- <el-table-column
              prop="frozen_amount"
              label="扣除金额">
            </el-table-column> -->
            <el-table-column
              label="状态"
              width="60">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">正常</div>
                <div class='fail' v-if="scope.row.state == 0">停用</div>
              </template>
            </el-table-column>            
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <!-- <el-button size="mini" @click="merchant_manage(scope.row, 'enable')" v-if="scope.row.state == 0">开启</el-button>
                <el-button size="mini" @click="merchant_manage(scope.row, 'disable')" v-if="scope.row.state == 1">关闭</el-button>
                <el-button size="mini" @click="reset_pw(scope.row)" v-if="scope.row.state == 1">重置登录密码</el-button>
                <el-button size="mini" @click="get_gtoken(scope.row)" v-if="scope.row.state == 1">查看秘钥</el-button>
                <el-button size="mini" @click="white_list(scope.row)" v-if="scope.row.state == 1">设置白名单</el-button>
                <el-button size="mini" @click="frozen(scope.row)" v-if="scope.row.state == 1">冻结余额</el-button>
                <el-button size="mini" @click="edit_recharge(scope.row)" v-if="scope.row.state == 1">修改手续费</el-button>
                <el-button size="mini" @click="channel_manage(scope.row)" v-if="scope.row.state == 1">通道管理</el-button> -->
                <el-button size="mini" @click="operate(scope.row)">操作</el-button>           
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

      <el-dialog
        title="操作"
        :visible.sync="operate_dialog"
        width="100%"
      >
      <div>
        <el-button size="mini" @click="merchant_manage(row, 'enable')" v-if="row.state == 0">开启</el-button>
        <el-button size="mini" @click="merchant_manage(row, 'delete')" v-if="row.state == 0">删除</el-button>
        <el-button size="mini" @click="merchant_manage(row, 'disable')" v-if="row.state == 1">关闭</el-button>
        <el-button size="mini" @click="reset_pw(row)" v-if="row.state == 1">重置登录密码</el-button>      
        <el-button size="mini" @click="get_gtoken(row)" v-if="row.state == 1">查看秘钥</el-button>
      </div>
      <div>
        <el-button size="mini" @click="white_list(row)" v-if="row.state == 1">设置白名单</el-button>
        <el-button size="mini" @click="frozen(row, '扣除余额')" v-if="row.state == 1">扣除余额</el-button>
        <el-button size="mini" @click="frozen(row, '增加金额')" v-if="row.state == 1">增加余额</el-button>        
      </div>
      <div>
        <el-button size="mini" @click="edit_recharge(row)" v-if="row.state == 1">修改手续费</el-button>
        <el-button size="mini" @click="channel_manage(row)" v-if="row.state == 1">通道管理</el-button>
      </div>
        
    </el-dialog>

      <el-dialog
        title="新增商户"
        :visible.sync="add_merchant_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="new_merchant_info"
        :rules="InputRules"
        ref="add_merchant"
        label-width="180px"
        class="form_regist"
      >
        <el-col :span="20">
          <el-form-item label="商户名称" prop="name">
            <el-input
              v-model="new_merchant_info.name"
              placeholder="请输入商户名称"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="商户缩写" prop="name_ab">
            <el-input
              v-model="new_merchant_info.name_ab"
              placeholder="请输入商户缩写"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>    
        </el-col>
        <el-col :span="20">    
          <el-form-item label="选择服务商">
            <el-select v-model="new_merchant_info.service_ab" placeholder="请选择" style="width: 100%" size="mini">
                    <el-option
                      v-for="item in service_info"
                      :key="item.ab"
                      :label="item.name"
                      :value="item.ab">
                    </el-option>
                  </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="服务商代收手续费" prop="recharge_per">
            <el-input
              v-model="new_merchant_info.recharge_per"
              placeholder="请输入服务商代收手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="服务商代收单笔手续费" prop="recharge_single">
            <el-input
              v-model="new_merchant_info.recharge_single"
              placeholder="请输入服务商代收单笔手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="服务商代付手续费" prop="wd_per">
            <el-input
              v-model="new_merchant_info.wd_per"
              placeholder="请输入服务商代付手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="服务商代付单笔手续费" prop="wd_single">
            <el-input
              v-model="new_merchant_info.wd_single"
              placeholder="请输入服务商代付单笔手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <div>提示：商户登录名为商户缩写，密码为<span style="color:red">123456</span></div>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="add_merchant_dialog = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="commit_merchant" size="mini">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="重置登录密码"
        :visible.sync="reset_pw_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_infos"
        :rules="InputRules"
        ref="reset_pw"
        label-width="120px"
        class="form_regist"
      >
          <div>主账号：{{ this.row.main_account}}</div>
        <el-form-item label="谷歌验证码" prop="gtoken">
          <el-input
            v-model="edit_infos.gtoken"
            placeholder="请输入谷歌验证码"
            style="width: 100%"
            maxlength="6"
            size="mini"
          >
          </el-input>
        </el-form-item>
        <div>提示：重置后密码为 <span style="color: red">123456</span></div>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="reset_pw_dialog = false; edit_infos={}" size="mini">取 消</el-button>
        <el-button type="primary" @click="commit_pw" size="mini">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="设置白名单"
        :visible.sync="set_white_list_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_infos"
        :rules="InputRules"
        ref="white_list"
        label-width="70px"
        class="form_regist"
      >
        <el-form-item label="白名单" prop="IP">
          <el-input
            v-model="edit_infos.IP"
            placeholder="请输入白名单IP"
            style="width: 100%"
            size="mini"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="set_white_list_dialog = false; edit_infos={};" size="mini">取 消</el-button>
        <el-button type="primary" @click="commit_white_list" size="mini">确 定</el-button>
        <el-button type="primary" @click="commit_white_list('clear')" size="mini">清 空</el-button>
      </span>
    </el-dialog>

    <el-dialog
        :title=frozen_type
        :close-on-click-modal="false"
        :visible.sync="frozen_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_infos"
        :rules="InputRules"
        ref="frozen_amount"
        label-width="90px"
        class="form_regist"
      > 
        <el-col :span="16">
        <el-form-item label="选择钱包">
          <el-select v-model="edit_infos.wallet_add" placeholder="请选择" style="width: 100%">
            <el-option
              v-for="(item, index) in wallet_info"
              :key="item.wallet_add"
              :label="item.wallet_name"
              :value="item.wallet_add"
              @click.native ="wallet_change(index)">
            </el-option>
          </el-select>
        </el-form-item>
        </el-col>       
        <el-col :span="16">
        <el-form-item :label=frozen_type prop="amount">
          <el-input
            v-model="edit_infos.amount"
            placeholder="请输入金额"
            maxlength="12"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        </el-col>
        
        <el-col :span="16">
        <el-form-item label="备注">
          <el-input
            v-model="edit_infos.remark"
            placeholder=""
            maxlength="100"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        </el-col>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog">取 消</el-button>
        <el-button type="primary" @click="commit_frozen"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
        title="Google秘钥"
        :visible.sync="gtoken_dialog"
        width="100%"
        @close="close_qrcode"
      >
      <!--      内容主体区域-->
      <el-form
        label-width="120px"
        class="form_regist"
      >
        <div>Google秘钥：<span style="color: #24a9f6">{{edit_infos.gtoken}}</span></div>
        <div id="qrcode" style="padding-top: 30px"></div>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_qrcode">确 定</el-button>
        <el-button @click="change_qrcode">更改秘钥</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="修改服务商手续费"
        :visible.sync="recharge_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_infos"
        :rules="InputRules"
        ref="recharge"
        label-width="120px"
        class="form_regist"
      > 
        <el-col :span="20">
          <el-form-item label="代收手续费" prop="recharge_per">
            <el-input
              v-model="edit_infos.recharge_per"
              placeholder="请输入代收手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代收单笔手续费" prop="recharge_single">
            <el-input
              v-model="edit_infos.recharge_single"
              placeholder="请输入代收单笔手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付手续费" prop="wd_per">
            <el-input
              v-model="edit_infos.wd_per"
              placeholder="请输入代付手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付单笔手续费" prop="wd_single">
            <el-input
              v-model="edit_infos.wd_single"
              placeholder="请输入代付单笔手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="recharge_dialog = false; edit_infos={};" size="mini">取 消</el-button>
        <el-button type="primary" @click="commit_recharge" size="mini">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="通道管理"
        :visible.sync="channle_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <div style="position:relative; background-color: #1ba794; height: 40px; line-height: 40px; padding: 0 40px; margin-bottom: 10px;" id="header">
      <span style="font-size: 16px; color: white">{{this.row.name}}</span>        
        <div style="float: right">
            <el-button @click="transfer_balance" type="primary" round size="mini">转换余额</el-button>
            <el-button @click="add_channel" type="primary" round size="mini">增加通道</el-button>
        </div>
      </div>
      <el-table
      :data="channel_infos"
      style="width: 100%"
      :header-cell-style="{textAlign: 'center'}"
      :cell-style="{ textAlign: 'center' }">
        <el-table-column
          prop="name"
          label="通道名称">
        </el-table-column>
        <el-table-column
          prop="amount"
          label="钱包余额">
        </el-table-column>        
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="edit_wallet(scope.row)">修改</el-button>
            <el-button size="mini" @click="set_default(scope.row, 'collection')" v-if="row.default_collection != scope.row.wallet_add">设为默认代收</el-button>
            <el-button size="mini" @click="set_default(scope.row, 'pay')" v-if="row.default_pay != scope.row.wallet_add">设为默认代付</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog" size="mini">确  定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="增加通道"
        :visible.sync="channel_select_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_infos"
        :rules="InputRules"
        ref="channel_select"
        label-width="130px"
        class="form_regist"
      > 
        <el-col :span="20">
          <el-form-item label="选择通道">
            <el-select v-model="channel_ab" placeholder="请选择" style="width: 120px" size="mini">
              <el-option
                v-for="item in channel_select"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="20" v-show="channel_ab">
          <el-form-item label="钱包名称" prop="name">
            <el-input
              v-model="edit_infos.name"
              placeholder="请输入钱包名称"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="20" v-show="channel_ab">
          <el-form-item label="代收手续费" prop="recharge_per">
            <el-input
              v-model="edit_infos.recharge_per"
              placeholder="请输入代收手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20" v-show="channel_ab">
          <el-form-item label="代收单笔手续费" prop="recharge_single">
            <el-input
              v-model="edit_infos.recharge_single"
              placeholder="请输入代收单笔手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20" v-show="channel_ab">
          <el-form-item label="代付手续费" prop="wd_per">
            <el-input
              v-model="edit_infos.wd_per"
              placeholder="请输入代付手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20" v-show="channel_ab">
          <el-form-item label="代付单笔手续费" prop="wd_single">
            <el-input
              v-model="edit_infos.wd_single"
              placeholder="请输入代付单笔手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代收单笔最大金额" prop="max_recharge">
            <el-input
              v-model="edit_infos.max_recharge"
              placeholder="请输入代收单笔最大金额"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代收单笔最小金额" prop="min_recharge">
            <el-input
              v-model="edit_infos.min_recharge"
              placeholder="请输入代收单笔最小金额"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付单笔最大金额" prop="max_wd">
            <el-input
              v-model="edit_infos.max_wd"
              placeholder="请输入代付单笔最大金额"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付单笔最小金额" prop="min_wd">
            <el-input
              v-model="edit_infos.min_wd"
              placeholder="请输入代付单笔最小金额"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="channel_select_dialog = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="commit_channel" size="mini">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="修改配置"
        :visible.sync="edit_wallet_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_infos"
        :rules="InputRules"
        ref="edit_wallet"
        label-width="140px"
        class="form_regist"
      > 
        <el-col :span="20">
          <el-form-item label="通道名称">
            <el-input
              v-model="edit_infos.name"
              :disabled="true"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代收手续费" prop="recharge_per">
            <el-input
              v-model="edit_infos.recharge_per"
              placeholder="请输入代收手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代收单笔手续费" prop="recharge_single">
            <el-input
              v-model="edit_infos.recharge_single"
              placeholder="请输入代收单笔手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付手续费" prop="wd_per">
            <el-input
              v-model="edit_infos.wd_per"
              placeholder="请输入代付手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            <span slot="suffix" style="font-style:normal;margin-right: 10px;">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付单笔手续费" prop="wd_single">
            <el-input
              v-model="edit_infos.wd_single"
              placeholder="请输入代付单笔手续费"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代收单笔最大金额" prop="recharge_max">
            <el-input
              v-model="edit_infos.recharge_max"
              placeholder="请输入代收单笔最大金额"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代收单笔最小金额" prop="recharge_min">
            <el-input
              v-model="edit_infos.recharge_min"
              placeholder="请输入代收单笔最小金额"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付单笔最大金额" prop="wd_max">
            <el-input
              v-model="edit_infos.wd_max"
              placeholder="请输入代付单笔最大金额"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="代付单笔最小金额" prop="wd_min">
            <el-input
              v-model="edit_infos.wd_min"
              placeholder="请输入代付单笔最小金额"
              type="number"
              style="width: 100%"
              size="mini"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="edit_wallet_dialog = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="commit_wallet" size="mini">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog
        title="划拨余额"
        :visible.sync="transfer_balance_dialog"
        width="100%"
      >
      <!--      内容主体区域-->
      <el-form
        :model="edit_infos"
        :rules="InputRules"
        ref="transfer_balance"
        label-width="120px"
        class="form_regist"
      > 
        <el-col :span="16">
        <el-form-item label="选择转出钱包">
          <el-select v-model="edit_infos.from_add" placeholder="请选择" style="width: 100%" size="mini">
            <el-option
              v-for="(item, index) in wallet_info"
              :key="item.wallet_add"
              :label="item.wallet_name"
              :value="item.wallet_add"
              @click.native ="wallet_change(index)">
            </el-option>
          </el-select>
        </el-form-item>
        </el-col>
        
        <el-col :span="16">
        <el-form-item label="选择转入钱包">
          <el-select v-model="edit_infos.to_add" placeholder="请选择" style="width: 100%" size="mini">
            <el-option
              v-for="(item, index) in wallet_info"
              :key="item.wallet_add"
              :label="item.wallet_name"
              :value="item.wallet_add">
            </el-option>
          </el-select>
        </el-form-item>
        </el-col>
      </el-form>
      <!--      底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_dialog" size="mini">取 消</el-button>
        <el-button type="primary" @click="commit_transfer" size="mini">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { merchant_query_api, merchant_query_export_api,  get_service_api, reg_merchant_api, merchant_manage_api, get_wallet_api, get_channel_api, get_channel_name_api, 
create_wallet_api, edit_wallet_api, frozen_wallet_amount_api, set_default_wallet_api, transfer_balance_api} from "../network/api/merchant";
import { MessageBox} from "element-ui";
import { reset_pw_api, get_gtoken_api } from '../network/api/user'
import paging from "../components/pagenation";
import QRCode from "qrcodejs2";

export default {  
  name: "h5_merchant_manage",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '正常'
        },
        {
          value: 0,
          label: '禁用'
        },
        ],
      edit_infos:{},
      query_condition: {
        create_time: undefined,
        end_time: undefined,     
        state: "",
        merchant_name: '',
        service_name: '',
      },       
      user_name: '',
      merchant_info: [],
      channel_infos: [],
      channel_select: [],
      add_merchant_dialog: false,
      reset_pw_dialog:false,
      set_white_list_dialog: false,
      frozen_dialog: false,
      gtoken_dialog: false,
      channle_dialog: false,
      recharge_dialog: false,
      channel_select_dialog: false,
      edit_wallet_dialog: false,
      transfer_balance_dialog: false,
      operate_dialog: false,
      new_merchant_info: {},
      service_info: [],
      wallet_info: [],
      wallet_amount: 0,
      frozen_amount: 0,
      row_infos: {},
      row:{},
      channel_row: {},
      channel_ab: '', 
    }
  },
  created() {
    this.query()
  },
  methods:{
  currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
  structure_data(){
    return {
        state: this.query_condition.state,
        start_time: this.$globalJs.picker_time(this.query_condition.start_time, 0), 
        end_time: this.$globalJs.picker_time(this.query_condition.end_time, 0), 
        _name: this.query_condition.merchant_name,
        _service_name: this.query_condition.service_name,
        page: this.page.page,
        page_size: this.page.page_size
      }
    },
  query(){
      this.startLoading("查询中。。")      
      merchant_query_api(this.structure_data()).then((res) => {       
          this.endLoading() 
          if (res.code === 200) {
            this.merchant_info = res.data.datas
            this.merchant_info.forEach(item => {
            item.create_time = this.$globalJs.picker_time(item.create_time, 1)
            item.amount = this.$globalJs.format_digit(item.balance)
            item.frozen_amount = this.$globalJs.format_digit(item.frozen)
            })
            this.page.total = res.data.total
          }else{
            this.$message.error(res.message)
          }
    })
    },
  query_export(){      
      this.startLoading("查询中。。")   
      merchant_query_export_api(this.structure_data()).then((res) =>{
        this.endLoading()
        this.$globalJs.download_file(res, '商户管理')
      })      
  },
  reset_condition(){
    this.query_condition = {
      start_time: undefined,
      end_time: undefined,     
      state: "",
      merchant_name: '',
      service_name: '',
    }
    this.page.page = 1
    this.query()
},
  compare_value(data){
      for (let i=0; i < data.length; i++){
        const value = '' + this.row[data[i]]
        if (value.includes('%')) value = value.slice(0, value.length - 1)
        if (this.edit_infos[data[i]] != value) return false      
      }
      return true
  },
  add_merchant(){
    this.startLoading('获取服务商数据中。。')
    get_service_api().then((res) =>{
      this.endLoading()
      if (res.code === 200) {
        this.service_info = res.data
        this.new_merchant_info = {}            
        this.add_merchant_dialog = true
        this.$set(this.new_merchant_info, 'service_ab', this.service_info[0].ab)
      }else this.$message.error('获取数据失败，请重试')
    })
    
    
  },
  commit_merchant(){
      this.$refs.add_merchant.validate((valid) => {
        if (!valid) return;
        this.startLoading('插入数据中。。')
        const data = {
          merchant_name: this.new_merchant_info.name,
          merchant_ab: this.new_merchant_info.name_ab,
          service_ab: this.new_merchant_info.service_ab,
          recharge_per: this.new_merchant_info.recharge_per,
          recharge_single: this.new_merchant_info.recharge_single,
          wd_per: this.new_merchant_info.wd_per,
          wd_single: this.new_merchant_info.wd_single
        }
        reg_merchant_api(data).then((res) => {
          this.endLoading()
          if (res.code === 200){
            this.add_merchant_dialog = false;
            this.$message.success('添加成功')           
            this.query() 
          } else this.$message.error(res.data)
        })
        
      });
    },
  edit_row(row, type, params){
    if (type === 'disable') row.state = 0
    else if (type === 'enable') row.state = 1
    else if (type === 'white_list') row.IP = params
    else if (type === 'edit_recharge') {
      const key = ['recharge_per', 'recharge_single', 'wd_per', 'wd_single']
      for (let i=0; i<key.length; i++){
        row[key[i]] = params[key[i]]
      }
    }else this.$message.error('操作错误！！')
  },
  merchant_manage(row, type, params=null){
    this.startLoading('插入数据中。。')
    const data = {ab: row.ab, type: type}
    if (params) data['data'] = params
    merchant_manage_api(data).then((res)=>{
      this.endLoading()
      if (res.code === 200){
            this.$message.success('修改成功')                       
            this.edit_info = {}
            this.close_dialog()
            if (type == 'delete') this.query()
            else this.edit_row(row, type, params)
          } else this.$message.error(res.message)
    })    
  },
  reset_pw(row){
    this.reset_pw_dialog = true
    this.row = row
  },
  commit_pw(){
    this.$refs.reset_pw.validate((valid) => {
        if (!valid) return;
        this.startLoading('插入数据中。。')
        const data = {type: 'other', password: this.$md5('123456'), gtoken: this.edit_infos.gtoken, merchant: this.row.ab}        
        reset_pw_api(data).then((res)=>{
          this.endLoading()
          if (res.code === 200){
            this.$message.success('修改成功, 默认登录密码为123456') 
            this.edit_infos.gtoken = ''
            this.close_dialog()
          } else this.$message.error(res.message)
        })
    })    
  },
  white_list(row){
    this.set_white_list_dialog = true
    this.edit_infos = {}
    this.$set(this.edit_infos, 'IP', row.IP)
    this.row = row
  },
  commit_white_list(type){
    if (type == 'clear') this.merchant_manage(this.row, 'white_list', '');
    else{
      this.$refs.white_list.validate((valid) => {        
      if (!valid) return;
      if (this.compare_value(['IP'])) this.$message.warning('无修改')
      else this.merchant_manage(this.row, 'white_list', this.edit_infos.IP);
      })
    }
  },
  frozen(row, type){    
    this.frozen_type = type
    this.startLoading('获取钱包数据中。。')
    const data = {merchant_ab: row.ab}
    get_wallet_api(data).then((res)=>{
      this.endLoading()
      if (res.code === 200) {
        this.wallet_info = res.data
        if (this.wallet_info.length > 0){
          this.wallet_info.forEach(item => {
            item.wallet_name = item.name + ':' + this.$globalJs.format_digit(item.amount)
            })
          this.frozen_dialog = true
          this.row = row
        }else this.$message.warning('该商户没有相关钱包')
      }
      else this.$message.error(res.message)
    })   
  },
  commit_frozen(){
    this.$refs.frozen_amount.validate((valid) => {
        if (!valid) return;
        const amount = parseInt(this.edit_infos.amount)
        if (this.frozen_type == '扣除余额'){
          if (parseInt(this.wallet_amount) < amount) {
            this.$message.error('可用余额不足')
            return
            }
        }
            this.startLoading('修改数据中。。')
            const data= {
              wallet_add: this.edit_infos.wallet_add,
              amount: amount,
              remark: this.edit_infos.remark,
              type: this.frozen_type
            }
            frozen_wallet_amount_api(data).then((res)=>{
              this.endLoading()
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.close_dialog()
                if (this.frozen_type == '扣除余额'){
                  this.row.balance = (this.row.balance - amount).toFixed(2)
                  this.row.frozen = (this.row.frozen + amount).toFixed(2)
                }else this.row.balance = this.row.balance + amount
                this.row.amount = this.$globalJs.format_digit(this.row.balance)
                this.row.frozen_amount = this.$globalJs.format_digit(this.row.frozen)
              }
              else this.$message.error(res.message)
            })
    })
  },
  get_gtoken(row){
    this.startLoading('获取中。。')
    const data = {role: row.ab}
    get_gtoken_api(data).then((res)=>{
      this.endLoading()
      if (res.code===200){
        this.edit_infos.gtoken = res.data.gtoken
        this.gtoken_dialog = true
        this.create_qrcode(row.ab)
      }else this.$message.error('获取秘钥失败' + res.message)
    })    
  },
  create_qrcode(user_name){
    this.$nextTick(() =>{
    new QRCode('qrcode', {
        text: "otpauth://totp/" + user_name +"?secret=" + this.edit_infos.gtoken, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "black", //#000000为黑色
        colorLight: "white",
      });
  });
  },
  close_qrcode(){
    this.gtoken_dialog = false; 
    this.edit_infos = {}
    document.getElementById('qrcode').innerHTML = ''
  },
  change_qrcode(){
    this.close_qrcode()

    this.startLoading('修改')
    const data = {ab: this.row.ab, type: 'reset_google_token'}
    merchant_manage_api(data).then((res)=>{
      this.endLoading()
      if (res.code === 200){
            this.$message.success('修改成功')                       
            this.edit_info = {}
            this.get_gtoken(this.row)  
          } else this.$message.error(res.message)
    })     
  },
  edit_recharge(row){
    this.row = row
    this.$set(this.edit_infos, 'recharge_per', '' + row.recharge_per)
    this.$set(this.edit_infos, 'recharge_single', '' + row.recharge_single)
    this.$set(this.edit_infos, 'wd_per', '' + row.wd_per)
    this.$set(this.edit_infos, 'wd_single', '' + row.wd_single)
    this.recharge_dialog = true
  },  
  commit_recharge(){
      this.$refs.recharge.validate((valid) => {
        if (!valid) return
        if (this.compare_value(['recharge_per', 'recharge_single', 'wd_per', 'wd_single'])) this.$message.warning('无修改')
        else this.merchant_manage(this.row, 'edit_recharge', this.edit_infos)      
    })
  },
  channel_manage(row){    
    this.startLoading('获取通道数据中。。')
    this.row = row
    get_channel_api({merchant_ab: row.ab}).then((res)=>{
      this.endLoading()
      if (res.code === 200) {
        this.channle_dialog = true
        this.channel_infos = res.data
        this.channel_infos.forEach(item => {
            item.create_time = this.$globalJs.picker_time(item.create_time, 1)
            item.wd_per = item.wd_per + '%' 
            item.recharge_per = item.recharge_per + '%' 
            })
      }else this.$message(res.message + ',请重试')
      
    })
  },
  wallet_change(index){
    this.wallet_amount = this.wallet_info[index].amount
    this.frozen_amount = this.wallet_info[index].frozen
  },
  delete_wallet(){
    MessageBox.alert('功能尚未开发', '提示', { type: 'error' })
  },
  add_channel(){
    this.startLoading('获取通道信息。。')    
    get_channel_name_api().then((res)=>{
      this.endLoading()
      if (res.code === 200) {
        this.channel_select = res.data
        const temp_channel = []
        for (let i=0; i<this.channel_infos.length; i++){
          temp_channel.push(this.channel_infos[i].ab)
        }
        const temp_select = []
        for (let i=0; i<this.channel_select.length; i++){
          if (temp_channel.indexOf(this.channel_select[i].value) < 0) temp_select.push(this.channel_select[i])
        }
        this.channel_select = temp_select
        if (this.channel_select.length > 0) this.channel_select_dialog = true
        else this.$message.warning('没有可添加的新通道')
      }else this.$message.error('获取数据失败，请重试，失败原因：' + res.message)
    })
  },
  commit_channel(){
    this.$refs.channel_select.validate((valid) => {
      if (!valid) return
    this.startLoading('插入数据中。。')
    this.edit_infos.ab = this.row.ab
    this.edit_infos.channel_ab = this.channel_ab
    create_wallet_api(this.edit_infos).then((res)=>{
      this.endLoading()
      if (res.code === 200){
        this.$message.success('添加成功')
        this.channel_select_dialog = false
        if (res.data) {
          this.row.default_collection = res.data
          this.row.default_pay = res.data
        }
        this.channel_manage(this.row)

      }else this.$message.error(res.message)
    })
    })
  },
  edit_wallet(row){
    this.channel_row = row
    this.edit_infos.name = row.name
    this.edit_infos.wallet_name = row.wallet_name
    this.edit_infos.wallet_add = row.wallet_add
    this.row.amount = row.max
    this.row.min_amount = row.min
    this.$set(this.edit_infos, 'recharge_max', '' + row.recharge_max)
    this.$set(this.edit_infos, 'recharge_min', '' + row.recharge_min)
    this.$set(this.edit_infos, 'wd_max', '' + row.wd_max)
    this.$set(this.edit_infos, 'wd_min', '' + row.wd_min)
    this.$set(this.edit_infos, 'amount', '' + row.max)
    this.$set(this.edit_infos, 'min_amount', '' + row.min)
    this.$set(this.edit_infos, 'recharge_per', row.recharge_per.slice(0, row.recharge_per.length-1))
    this.$set(this.edit_infos, 'recharge_single', '' + row.recharge_single)
    this.$set(this.edit_infos, 'wd_per', row.wd_per.slice(0, row.wd_per.length-1))
    this.$set(this.edit_infos, 'wd_single', '' + row.wd_single)
    this.edit_wallet_dialog = true
  },
  commit_wallet(){
    this.$refs.edit_wallet.validate((valid) => {
        if (!valid) return
        if (this.compare_value(['recharge_per', 'recharge_single', 'wd_per', 'wd_single', 'recharge_max', 'recharge_min', 'wd_max', 'wd_min'])) {
          this.$message.warning('无修改')
          return
        }
        this.startLoading('插入数据中。。')
        delete this.edit_infos.name
        delete this.edit_infos.wallet_name
        edit_wallet_api(this.edit_infos).then((res)=>{
          this.endLoading()
          if (res.code === 200) {
            this.$message.success('修改成功')
            this.channel_row.recharge_per = this.edit_infos.recharge_per + '%'
            this.channel_row.recharge_single = this.edit_infos.recharge_single
            this.channel_row.recharge_per = this.edit_infos.recharge_per + '%'
            this.channel_row.recharge_single = this.edit_infos.recharge_single
            this.channel_row.wd_per = this.edit_infos.wd_per + '%'
            this.channel_row.recharge_max = this.edit_infos.recharge_max
            this.channel_row.recharge_min = this.edit_infos.recharge_min
            this.channel_row.wd_max = this.edit_infos.wd_max
            this.channel_row.wd_min = this.edit_infos.wd_min
            this.edit_wallet_dialog = false
            this.edit_infos = {}
          }else this.$message.error(res.message)
        })
    })
  },
  set_default(row, type){
    this.startLoading('设置中')
    const data = {
      type: type, 
      merchant_no: this.row.ab,
      wallet_add: row.wallet_add
    }
    set_default_wallet_api(data).then((res)=>{
      this.endLoading()
      if (res.code == 200){
        if (type == 'collection') this.row.default_collection = row.wallet_add
        else this.row.default_pay = row.wallet_add
      }
    })
  },
  transfer_balance(){
    this.startLoading('获取钱包数据中。。')
    const data = {merchant_ab: this.row.ab}
    get_wallet_api(data).then((res)=>{
      this.endLoading()
      if (res.code === 200) {
        this.wallet_info = res.data
        if (this.wallet_info.length > 1){
          this.wallet_info.forEach(item => {
            item.wallet_name = item.name + ':' + this.$globalJs.format_digit(item.amount)
            })
          this.transfer_balance_dialog = true
        }else this.$message.warning('该商户钱包数量少于1')
      }
      else this.$message.error(res.message)
    })       
  },
  commit_transfer(){
    if (this.edit_infos.from_add === this.edit_infos.to_add){
      this.$message.error('相同钱包无法转入')
      return
    }
    if (this.wallet_amount <= 0) {
      this.$message.error('钱包余额不足')
      return
    }
    this.startLoading('划拨余额中。。')
    transfer_balance_api(this.edit_infos).then((res)=>{
      this.endLoading()
      this.$message.success('划拨完成')
      this.channel_manage(this.row)
      this.close_dialog()
    })
  },
  operate(row){
    this.row=row
    this.operate_dialog = true

  },
  close_dialog(){
    this.reset_pw_dialog = false
    this.set_white_list_dialog = false
    this.frozen_dialog = false
    this.gtoken_dialog = false
    this.channle_dialog = false
    this.recharge_dialog = false
    this.channel_select_dialog = false
    this.edit_wallet_dialog = false
    this.transfer_balance_dialog = false
    this.edit_infos = {}
    this.wallet_amount = 0
    this.frozen_amount = 0
  }
  },
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 5px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.fail{
    height: 30px;
    width: 50px;
    background-color: red;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>

