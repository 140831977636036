<template>
  <div class="login-container">
    <div class="login_box">
      <!-- <div class="avatar_box">
        <img src="../assets/img/log.png" alt="" width="128ox" />
      </div> -->
      <div class="avatar_box">
        环球支付
      </div>
      <el-form
        label-width="auto"
        class="login_form"
        :model="loginForm"
        :rules="InputRules"
        ref="loginFormRef"
      >
        <!--         用户名-->
        <el-form-item prop="user_name">
          <el-input
            v-model="loginForm.user_name"
            prefix-icon="fa fa-phone"
            placeholder="请输入用户名"
            ref="telephone"
            @keyup.enter.native="enterPwd"
          ></el-input>
        </el-form-item>
        <!--         密码-->
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            prefix-icon="fa fa-lock"
            placeholder="请输入密码"
            @keyup.enter.native="enterGtoken"
            ref="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="gtoken">
          <el-input
            maxlength="6"
            v-model="loginForm.gtoken"
            prefix-icon="fa fa-lock"
            placeholder="请输入谷歌秘钥"
            @keyup.enter.native="userLogin"  
            ref="gtoken"
          ></el-input>
        </el-form-item>
        <!--         按钮区域-->
        <el-form-item
          class="btns"
          style="display: flex; justify-content: center; width: 100%"
        >
          <el-button
            type="primary"
            class="btns-items"
            @click="userLogin"
            style="width: 40%"
            >登录</el-button
          >
          </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login_api } from "../network/api/user";
export default {
  name: "login",
  data() {
    return {
      //这是登录表单的数据绑定对象
      loginForm: {
        user_name: "",
        password: "",
        gtoken: "",        
      },
      authority: {
          admin: '1,2,3,4,5,6,7,8,9,0,100,h4,h5,h6,h7,h8',
          techni:'1,2,3,4,5,6,7,8,9,0,100,h4,h5,h6,h7,h8',
          merchant: '1,10,11,12,13,14,15,16,21,h11,h12,h13,h14,h21',
          merchant_children: '1,10,11,12,13,15,h11,h12,h13,h14',
          service: '1,17,18,19,20',
          business: '1,2,3,4,5,6,7,8,h4,h5,h6,h8',
        },
      logining: false,
    };
  },
  created() {
  },
  methods: {
    userLogin() {
      this.$refs.loginFormRef.validate((valid) => {        
        if (!valid) return;
        if (this.logining) return;
        this.startLoading("登录中。。")
        let pwdInfo = this.loginForm.password;
        pwdInfo = this.$md5(pwdInfo);
        const data = {
          password: pwdInfo,
          user_name: this.loginForm.user_name,
          gtoken: this.loginForm.gtoken,
        };
        this.logining = true
        login_api(data).then((res) => {       
          this.endLoading()   
          this.logining = false
          if (res.code === 200) {
            this.$message.success("登录成功");            
            window.sessionStorage.setItem("user_name", res.data.username);
            window.sessionStorage.setItem("role", res.data.role);
            window.sessionStorage.setItem("session", res.data.session);         
            let authority_id = this.authority[res.data.authority]
            window.sessionStorage.setItem('authority', authority_id)
            window.sessionStorage.setItem('nav_index', 0)
            const web_width = document.body.clientWidth
            if(res.data.role == 'admin' || res.data.role == 'business' ) {              
              if (web_width > 600) this.$router.push("/service");   
              else this.$router.push("H5collection")
            }
            else if (res.data.role == 'service') this.$router.push('/ServiceHome')
            else {
              if (web_width > 600) this.$router.push('/MerchantHome')
              else this.$router.push("H5MerchantCollection")
            }
          } else if (res.code === 1001) {
            this.$message.error("用户名不存在");
          }  else if (res.code === 1003) {
            this.$message.error("密码错误");
          }  else if (res.code === 407) {
            this.$message.error("谷歌验证码错误")
          } else if (res.code === 411){
            this.$message.warning('用户已停用，请联系管理员')
          }
          else {
            return this.$message.error("登录失败");
          }
          
        });
      });
    },
    enterPwd() {
      this.$refs.password.focus();
    },    
    enterGtoken() {
      this.$refs.gtoken.focus();
    },
  },
};
</script>


<style scoped>
.login-container {
  background: url("../assets/img/login-bg.png");
  background-size: 100%;
  width: 100%;
  height: 100vh;
  position: fixed;
}
.login_box {
  width: 400px;
  background-color: white;
  border-radius: 3px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 2%;
  padding-bottom: 1%;
}
.avatar_box {
  height: 180px;
  text-align: center;
  color: black;
  font-size: 40px;
  line-height: 100px;
  background-color: white;
}
.login_form {
  margin-top: 20px;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  overflow: hidden;
  margin-bottom: 0;
  margin-top: 40px;
}
.btns >>> .el-form-item__content {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btns-items {
  float: left;
}
.login_form >>> .el-input__inner {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  /*outline: medium;*/
}
</style>