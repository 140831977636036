export default {
    set_hour(time, hour=-8){
      let date = new Date(time)
      date = date.setHours(date.getHours() + hour)
      return date
    },
    picker_time(time, sub=1, flag=1) {
      if (time == undefined) return ''
      if (time.length === 0) return ['', '']
      if (time.length === 2) {return [this.picker_time(time[0], 0), this.picker_time(time[1], 0)]}     
      if (sub === 1) time = this.set_hour(time) 
      let date = new Date(time);
      if (date === 'Invalid Date') return ""      
      let y = date.getFullYear()
      let M = (date.getMonth() + 1 + '').padStart(2, '0')
      let d = (date.getDate() + '').padStart(2, '0')
      let h = (date.getHours() + '').padStart(2, '0');
      let m = (date.getMinutes() + '').padStart(2, '0');
      let s = (date.getSeconds() + '').padStart(2, '0')
      if (flag === 1) return `${y}-${M}-${d} ${h}:${m}:${s}`
      else if(flag === 2) return `${y}${M}${d}${h}${m}${s}`
      else if(flag === 3) return `${y}-${M}-${d}`
      else return 
    },
    download_file(res, file_name){
      const blobUrl = window.URL.createObjectURL(res);
      const a = document.createElement('a');
      a.download = file_name + this.picker_time(new Date(), 2) + '.xlsx';
      a.href = blobUrl;
      a.click();
      window.URL.revokeObjectURL(blobUrl)
    },
    format_digit(amount){
      let value = ('' + amount).split('.')
      let value_str = ''
      for (let i=0; i<value[0].length; i++){
          if ((value[0].length - i)%3 == 0 && i != 0) value_str += ','
          value_str += value[0][i]
      }
      if (value[1]) value_str += '.' + value[1]
      return value_str
    },
}



