<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">冲正查询</span>
        </div>

        <div style="display: flex; margin: 10px 0;">
            <div style="width: 480px;">
              <span>起止日期：</span>
              <el-date-picker
                v-model="query_condition.query_time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>            

            <el-button type="primary" @click="condition_query">查询</el-button>
            <!-- <el-button type="primary" @click="reset_condition">重置</el-button> -->
            <!-- <el-button type="primary" @click="query_export">导出</el-button> -->
        </div>


        <div class="table_list">
          <el-table
            :data="right_recode_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }"
            :row-class-name="color_table">
            <el-table-column
              prop="create_time"
              label="发生时间"
              width="132">
            </el-table-column>
            <el-table-column
              prop="order_no"
              label="商户订单号">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="冲正金额"
              width="68">
            </el-table-column>
            <el-table-column
              prop="type"
              label="状态"
              width="56">
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>
  </div>
</template>

<script>
import { right_query_api } from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "RightQuery",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      query_condition: {
        query_time: [], 
      },        
      right_recode_infos: [],
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      return {
        start_time: start_time, 
        end_time: end_time,
        page: this.page.page,
        page_size: this.page.page_size
      }
    },
    query(){        
        this.startLoading("查询中。。")      
        right_query_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.right_recode_infos = res.data.datas
                this.right_recode_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1)
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    reset_condition(){
      this.query_condition = {
        query_time: [], 
      }
      this.page.page = 1
      this.query()
    },
    color_table({row}){
      return row.amount > 0? 'add-row': 'sub-row'
    },
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>
<style>
  .el-table .add-row {
    background: oldlace;
    color: red
  }

  .el-table .sub-row {
    background: #f0f9eb;
    color: green
  }
</style>



