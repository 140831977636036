import request from "../request";
export function home_api(data) {
return request({
    url: '/service/home',
    method:'get',
    params: data
})
}
export function service_collection_api(data) {
return request({
    url: '/service/service_collection',
    method:'get',
    params: data
})
}
export function service_sum_api(data) {
    return request({
        url: '/service/service_sum',
        method:'get',
        params: data
    })
    }
export function service_pay_api(data) {
return request({
    url: '/service/service_pay',
    method:'get',
    params: data
})
}
export function service_recode_api(data) {
return request({
    url: '/service/service_recode',
    method:'get',
    params: data
})
}