import request from "../request";
export function login_api(data) {
  return request({
    url: '/user/login',
    method:'post',
    data: data
  })
}
export function reset_pw_api(data) {
  return request({
    url: '/user/reset_pw',
    method:'post',
    data: data
  })
}
export function get_gtoken_api(data) {
  return request({
    url: '/user/get_gtoken',
    method:'get',
    params: data
  })
}
export function register_api(data) {
  return request({
    url: '/user/register',
    method:'post',
    data: data
  })
}
export function disable_account_api(data) {
  return request({
    url: '/user/disable_account',
    method:'post',
    data: data
  })
}
export function get_children_account_api(data) {
  return request({
    url: '/user/get_children_account',
    method:'get',
    params: data
  })
}
