<template>
<div id="app" v-if="this.$route.path != '/Login' && big">
    <el-container>      
      <el-header style="width:100%; display: flex; background-color: #1ba794;"> 
      <div class='hide_icon' :class="{'el-icon-d-arrow-right': is_hide, 'el-icon-d-arrow-left': !is_hide}" @click="change_aside"></div>
      <NavBar></NavBar>
      </el-header>
    </el-container>
    <el-container style="height: calc(100% - 60px)">
      <el-aside v-if="!is_hide" class="left" :class="{bigleft: !isCollapse}" width="64px">
        <LeftSide></LeftSide>
      </el-aside>
      <el-main><router-view></router-view></el-main>
    </el-container>      
  </div>
  <div id="app" v-else-if = "this.$route.path != '/Login' && !big">
    <el-container>      
      <el-header style="height: 40px"> 
      <LeftSide></LeftSide>
      </el-header>
    </el-container>
    <el-container style="height: calc(100% - 40px)">
      <el-main><router-view></router-view></el-main>
    </el-container>      
  </div>
  <div id="app" v-else>
    <router-view></router-view>
  </div>
 
  

</template>

<script>
import NavBar from './components/NavBar.vue'
import LeftSide from './components/aside.vue'

export default {
  name: 'app',
  components:{
    NavBar: NavBar,
    LeftSide: LeftSide
  },
  data(){
    return{
      is_hide: false,
      isCollapse: false,
      big: true
    }
  },
  created(){
    this.init()
  },
  methods: {
    init(){
      if (document.body.clientWidth < 600) {
        this.isCollapse = true
        this.big = false
        }
      else this.isCollapse = false
    },
    change_aside(){
      this.is_hide = !this.is_hide
    },
  }
}
</script>
<style scoped>
.hide_icon{
    font-size: 44px;
    color: white;
    padding: 10px;
    cursor: pointer;
}
.left{
  height: 100%;
  background-color: #1fbba6;
}
.bigleft{
  width: 200px !important
}
</style>
