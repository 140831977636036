<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 80px; line-height: 80px; padding: 0 40px; margin-bottom: 10px;" id="header">
          <span style="font-size: 26px; color: white">收款查询</span>
        </div>

        <div style="display: flex; margin: 10px 0;font-size: 12px;">
            <div style="width: 280px; margin-right: 20px;">
              <span>开始日期：</span>
              <el-date-picker
                v-model="query_condition.start_time"
                type="datetime"
                range-separator="至"
                placeholder="开始日期"
                default-time="00:00:00"
                size="mini">
              </el-date-picker>
            </div>
            <div style="width: 280px; margin-right: 20px">
              <span>结束日期：</span>
              <el-date-picker
                v-model="query_condition.end_time"
                type="datetime"
                range-separator="至"
                placeholder="结束日期"
                default-time="23:59:59"
                size="mini">
              </el-date-picker>
            </div>
            <div style="display: flex; line-height: 30px; margin-right: 20px">
              <span style="width: 79px;">通道名称：</span>
              <el-input v-model="query_condition._channel" placeholder="请输入通道名称" size="mini"></el-input>
            </div>     
            <div style="display: flex; line-height: 30px; margin-right: 20px">
              <span style="width: 48px;">服务商：</span>
              <el-input v-model="query_condition._service" placeholder="请输入通道名称" size="mini" style="width: 224px"></el-input>
            </div>          
            <el-button type="primary" @click="condition_query" size="mini">查询</el-button>     
        </div>
        
        <div style="display: flex; margin: 10px 0;font-size: 12px;">
          <div style="display: flex; line-height: 30px; margin-right: 20px">
            <span style="width: 72px;">渠道订单号：</span>
            <el-input v-model="query_condition.channel_order_no" placeholder="请输入渠道订单号" size="mini" style="width: 208px"></el-input>
          </div>  
          <div style="display: flex; line-height: 30px; margin-right: 20px">
            <span style="width: 72px;">商户订单号：</span>
            <el-input v-model="query_condition.merchant_order_no" placeholder="请输入商户订单号" size="mini" style="width: 208px"></el-input>
          </div> 
          <div style="display: flex; line-height: 30px;margin-right: 20px">
            <span style="width: 79px;">商户名称：</span>
            <el-input v-model="query_condition._merchant" placeholder="请输入商户名称" size="mini"></el-input>
          </div>                   
          <div style="display: flex; line-height: 30px;margin-right: 20px">
                <div>状态：</div>
                    <el-select v-model="query_condition.state" placeholder="请选择" style="width: 80px" size="mini">
                    <el-option
                        v-for="item in state_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>                
            </div>
          <div style="display: flex; line-height: 30px; margin-right: 20px">
            <span style="width: 36px;">金额：</span>
            <el-input v-model="query_condition.amount" placeholder="请输入金额" size="mini" style="width: 100px"></el-input>
          </div>        
          <el-button type="primary" @click="reset_condition" size="mini">重置</el-button>
          <div style="margin-left: 10px;">
            <el-popover
              placement="right"
              width="220"
              trigger="click">
              <div>
                <p>金额：{{this.all_info.amount}}</p>
                <p>手续费：{{this.all_info.charge}}</p>
                <p>笔数：{{this.all_info.times}}</p>
                <p>结算：{{this.all_info.settlement}}</p>
              </div>
              <el-button slot="reference" type="primary" @click="get_sum" size="mini" style="height: 30px !important">统计</el-button>
            </el-popover>
          </div>
        </div>

        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }"
            border>
            <el-table-column
              label="状态"
              width="72">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">成功</div>
                <div class='proccess' v-else>处理中</div>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="104">
              <template slot-scope="scope">
                <el-button size="mini" @click="success(scope.row)" v-if="scope.row.state == 0">手动成功</el-button>
                <el-button size="mini" @click="notice(scope.row)" v-if="scope.row.state == 1">补发通知</el-button>
              </template>
            </el-table-column>            
            <el-table-column
              prop="create_time"
              label="发起时间"
              width="134">
            </el-table-column>            
            <el-table-column
              prop="merchant"
              label="商户名称"
              width="102">
            </el-table-column>            
            <el-table-column
              prop="channel_order_no"
              label="通道订单号"
              width="310">
            </el-table-column>
            <el-table-column
              prop="merchant_order_no"
              label="商户订单号"
              width="210">
            </el-table-column>
            <el-table-column
              prop="pay_amount"
              label="收款金额">
            </el-table-column>
            <el-table-column
              prop="processing_time"
              label="处理时间"
              width="134">
            </el-table-column>
            <el-table-column
              prop="channel"
              label="通道名称"
              width="122">
            </el-table-column>
            <el-table-column
              prop="channel_charge"
              label="通道手续费"
              width="82">
            </el-table-column>
            <el-table-column
              prop="pay_realamount"
              label="结算金额">
            </el-table-column>           
            <el-table-column
              prop="service"
              label="服务商名称"
              width="82">
            </el-table-column>
            <el-table-column
              prop="service_charge"
              label="服务商手续费"
              width="94">
            </el-table-column>      
            
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
          <!-- <div style="display: flex; background-color: #1fbba6; width: 100%; height: 30px; line-height: 30px; color: white; padding: 5px 10px; margin-top: 10px;"> 
              <div style="width: 220px">金额合计: {{ this.all_info.amount }} </div>
              <div style="width: 130px">笔数合计: {{ this.all_info.times }} </div>
              <div style="width: 220px">手续费合计: {{ this.all_info.charge }} </div>
              <div style="width: 220px">结算合计: {{ this.all_info.settlement }} </div>
              <el-button size="mini" @click="get_sum">获取数据</el-button>  
          </div> -->
        </div>

        <el-dialog
        title="提示"
        :close-on-click-modal="false"
        :visible.sync="success_dialog"
        width="25%"
        >
        <!--      内容主体区域-->
        <el-form
            :model="edit_infos"
            :rules="InputRules"
            ref="success"
            label-width="120px"
            class="form_regist"
        >
            <el-form-item label="渠道订单号" prop="channel_order_no">
            <el-input
                v-model="edit_infos.channel_order_no"
                placeholder="请输入渠道订单号"
                style="width: 100%"
            >
            </el-input>
            </el-form-item>
            <el-form-item label="谷歌验证码" prop="gtoken">
            <el-input
                v-model="edit_infos.gtoken"
                placeholder="请输入谷歌验证码"
                style="width: 100%"
                maxlength="6"
            >
            </el-input>
            </el-form-item>
        </el-form>
        <!--      底部区域-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog">取 消</el-button>
            <el-button type="primary" @click="commit_success"
            >确 定</el-button
            >
        </span>
        </el-dialog>   

        <el-dialog
        title="提示"
        :close-on-click-modal="false"
        :visible.sync="resend_dialog"
        width="25%"
        >
        <!--      内容主体区域-->
        <el-form
            :model="edit_infos"
            :rules="InputRules"
            ref="resend"
            label-width="120px"
            class="form_regist"
        >
            <el-form-item label="谷歌验证码" prop="gtoken">
            <el-input
                v-model="edit_infos.gtoken"
                placeholder="请输入谷歌验证码"
                style="width: 100%"
                maxlength="6"
            >
            </el-input>
            </el-form-item>
        </el-form>
        <!--      底部区域-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog">取 消</el-button>
            <el-button type="primary" @click="commit_resend"
            >确 定</el-button
            >
        </span>
        </el-dialog>   

  </div>
</template>

<script>
import { pay_api, collection_success_api, resend_notice_api, cal_query_api } from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "collection",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '成功'
        },
        {
          value: 0,
          label: '未支付'
        },
        ],
      query_condition: {
        start_time: undefined,
        end_time: undefined,
        state: "",
        _merchant: '',
        _service: '',
        _merchant_order_no: '',
        _channel_order_no: '',
        _channel: '',
        amount: '',
      },        
      collection_infos: [],
      success_dialog: false,
      resend_dialog: false,
      edit_infos: {},
      all_info: {
        amount: 0,
        times: 0,
        charge: 0,
        settlement: 0
      },
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(type=1){
      let data = {
        start_time: this.$globalJs.picker_time(this.query_condition.start_time, 0), 
        end_time: this.$globalJs.picker_time(this.query_condition.end_time, 0), 
        state: this.query_condition.state,
        _merchant:  this.query_condition._merchant,
        _service:  this.query_condition._service,
        _merchant_order_no:  this.query_condition.merchant_order_no,
        _channel_order_no:  this.query_condition.channel_order_no,
        _channel:  this.query_condition._channel,
        amount:  this.query_condition.amount,
        type: 'extend'
      }
      if (type == 1) {
        data.page = this.page.page,
        data.page_size = this.page.page_size,
        data.type = 'query'
      }
      return data
    },
    query(){        
        this.all_info = {amount: 0, times: 0, charge: 0}
        this.startLoading("查询中。。")      
        pay_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1)
                item.processing_time = this.$globalJs.picker_time(item.processing_time, 1)
                item.pay_amount = this.$globalJs.format_digit(item.amount)
                item.realamount = item.amount - item.channel_charge
                item.pay_realamount = this.$globalJs.format_digit(item.amount - item.channel_charge)
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    reset_condition(){
      this.page.page = 1
        this.query_condition = {
        start_time: undefined,
        end_time: undefined, 
        state: "",
        _merchant: '',
        _service: '',
        _merchant_order_no: '',
        _channel_order_no: '',
        _channel: '',
        amount: '',        
      }
      this.page.page = 1
      this.query()
    },
    success(row){
        this.row = row
        this.edit_infos = {
            amount: row.amount,
            merchant_order_no: row.merchant_order_no,    
        }
        this.success_dialog = true
    },

    commit_success(){
        this.$refs.success.validate((valid) => {
            if (!valid) return
            this.startLoading('插入数据中')
            collection_success_api(this.edit_infos).then((res)=>{
                this.endLoading()
                if (res.code === 200){
                    this.$message.success('修改成功')
                    this.row.state = 1
                    this.row.channel_order_no = '手' + this.edit_infos.channel_order_no
                    this.row.processing_time = this.$globalJs.picker_time(this.$globalJs.set_hour(new Date(), 8))
                    this.close_dialog()
                }else this.$message.error(res.message)
            })        
        })
    },
    notice(row){
        this.edit_infos = {
            merchant_no: row.merchant_no,
            merchant_order_no: row.merchant_order_no,
            amount: row.amount,
            realamount: row.realamount,    
            callback_url: row.callback_url,
            type: 'pay',
            order_state: row.state,
        }
        this.resend_dialog = true
    },
    commit_resend(){
        this.$refs.resend.validate((valid) => {
            if (!valid) return
            resend_notice_api(this.edit_infos).then((res)=>{
                if (res.code === 200) {
                    this.$message.success('补发成功') 
                    this.close_dialog()
                    }
                else this.$message.error(res.message)
            })        
        })
    },
    close_dialog(){
        this.edit_infos = {}
        this.success_dialog = false
        this.resend_dialog = false
    },
    get_sum(){
      this.startLoading("查询中。。")   
      let data = this.structure_data(0)
      data.type = 'pay'
      cal_query_api(data).then((res)=>{
        this.endLoading()
        this.all_info = res.data
        this.all_info.settlement = this.all_info.amount - this.all_info.charge
      })
    },
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>

<style>
.el-table .success-row {
  background: #80e4e7;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.proccess{
    height: 30px;
    width: 50px;
    background-color: rgb(148, 95, 248);
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>

