<template>
    <div>
        <div style="position:relative; background-color: #1ba794; height: 30px; line-height: 30px; padding: 0 10px; margin-bottom: 3px;" id="header">
          <span style="font-size: 16px; color: white">充值审核</span>
          <div style="float: right">
            <el-button @click="condition_query" type="primary" round size="mini">刷新</el-button>
          </div>
        </div>

        <div class="table_list">
          <el-table
            :data="collection_infos"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }"
            :row-class-name="success_class">
            <el-table-column
              label="状态"
              width="60">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.state == 1">成功</div>
                <div class='proccess' v-if="scope.row.state == 0">审核中</div>
                <div class='fail' v-if="scope.row.state == 2">失败</div>
              </template>
            </el-table-column>
            <el-table-column
              label="发起时间"
              width="84">
              <template slot-scope="scope">
                <div>{{ scope.row.create_time[0]}}</div>
                <div>{{ scope.row.create_time[1]}}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="merchant_name"
              label="商户名称"
              width="23">
            </el-table-column>
            <el-table-column
              label="类型"
              width="60">
              <template slot-scope="scope">
                <div class='success' v-if="scope.row.type">充值</div>
                <div class='proccess' v-else>提现</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              label="交易金额">
            </el-table-column>            
            <el-table-column
              prop="add"
              label="地址">
            </el-table-column>   
            <el-table-column
              prop="remark"
              label="备注">
            </el-table-column>
            <el-table-column
              prop="operator"
              label="审核员">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                  <div>
                      <el-button size="mini" @click="audit(scope.row)" v-if="scope.row.state == 0">审核</el-button>
                  </div>               
              </template>
            </el-table-column>
          </el-table>
          <paging :page="page" @CurrentChange="currentpage"></paging>
        </div>

        <el-dialog
        title="提示"
        :visible.sync="gtoken_dialog"
        width="100%"
        >
        <!--      内容主体区域-->
        <el-form
            :model="edit_infos"
            :rules="InputRules"
            ref="gtoken"
            label-width="100px"
            class="form_regist"
            >               
            <el-col :span="18">
                <el-form-item label="选择钱包">
                <el-select v-model="edit_infos.wallet_add" placeholder="请选择" style="width: 100%" size="mini">
                    <el-option
                    v-for="item in wallet_infos"
                    :key="item.wallet_add"
                    :label="item.wallet_name"
                    :value="item.wallet_add">
                    </el-option>
                </el-select>
                </el-form-item>
            </el-col>
            <el-form-item label="谷歌验证码" prop="gtoken">
              <el-input
                  v-model="edit_infos.gtoken"
                  placeholder="请输入谷歌验证码"
                  style="width: 100%"
                  maxlength="6"
                  size="mini"
                  >
              </el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                  v-model="edit_infos.remark"
                  placeholder="请输入备注"
                  style="width: 100%"
                  maxlength="100"
                  size="mini"
                  >
              </el-input>
            </el-form-item>
        </el-form>
        <!--      底部区域-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="close_dialog" size="mini">取 消</el-button>
            <el-button type="primary" @click="commit_audit" size="mini">确 定</el-button>
        </span>
        </el-dialog>   

  </div>
</template>

<script>
import { all_recharge_api, audit_recharge_api, get_wallet_api } from "../network/api/merchant";
import paging from "../components/pagenation";

export default {  
  name: "H5RechargeAudit",
  components: {
    paging,
  },
  data(){
    return{        
      page:{
            page: 1,
            page_size: 10,
            total: 10
        },
      state_options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: 1,
          label: '成功'
        },
        {
          value: 0,
          label: '处理中'
        },
        ],
      query_condition: {
        query_time: [], 
        state: "",
        _merchant: '',
        _operator: '',
        order_no: '',
        _channel: '',
      },        
      collection_infos: [],
      gtoken_dialog: false,
      edit_infos: {},
      wallet_infos: [],
    }
  },
  created() {
    this.query()
  },
  methods:{
    currentpage(val){
      this.page.page = val
      this.query()
    },
    condition_query(){
      this.page.page = 1
      this.query()
    },
    structure_data(){
      const [start_time, end_time] = this.$globalJs.picker_time(this.query_condition.query_time)
      return {
        start_time: start_time, 
        end_time: end_time,
        state: this.query_condition.state,
        _merchant:  this.query_condition._merchant,
        _operator:  this.query_condition._operator,
        _channel:  this.query_condition._channel,
        order_no:  this.query_condition.order_no,
        page: this.page.page,
        page_size: this.page.page_size
      }
    },
    query(){        
        this.startLoading("查询中。。")      
        all_recharge_api(this.structure_data()).then((res)=>{
            this.endLoading()
            if (res.code === 200){
                this.collection_infos = res.data.datas
                this.collection_infos.forEach(item => {
                item.create_time = this.$globalJs.picker_time(item.create_time, 1).split(" ")
                item.processing_time = this.$globalJs.picker_time(item.processing_time, 1)
                if (item.amount < 0) {
                  item.type = 0
                  item.amount = -item.amount
                }else item.type = 1
                })
                this.page.total = res.data.total
            }else this.$message.error(res.message)
        })      
    },   
    reset_condition(){
      this.query_condition = {
        query_time: [], 
        state: "",
        _merchant: '',
        _operator: '',
        order_no: '',
        _channel: '',
      }
      this.page.page = 1
      this.query()
    },
    success_class({row}){
        if (row.state === '处理中') return 'proccess-row'
        return ''
    },
    audit(row){
        this.row = row
        this.edit_infos.order_no = row.order_no
        this.edit_infos.type = 'access'
        this.startLoading('获取钱包数据中。。')
        const data = {merchant_ab: row.merchant}
        get_wallet_api(data).then((res)=>{
        this.endLoading()
        if (res.code === 200) {
            this.wallet_infos = res.data
            if (this.wallet_infos.length > 0){
              this.wallet_infos.forEach(item => {
              item.wallet_name = item.name + ':' + this.$globalJs.format_digit(item.amount)
              })
              this.gtoken_dialog = true
            }else this.$message.warning('没有相关钱包，请联系管理员添加')
        }        
        else this.$message.error(res.message)
        })   
        
    },
    commit_audit(){
        this.$refs.gtoken.validate((valid) => {
            if (!valid) return
            this.startLoading('插入数据中')
            audit_recharge_api(this.edit_infos).then((res)=>{
                this.endLoading()
                if (res.code === 200){
                    this.$message.success('修改成功')
                    this.row.state = 1
                    this.row.operator = window.sessionStorage.getItem('user_name')
                    this.row.processing_time = this.$globalJs.picker_time(new Date())
                    this.row.remark = this.edit_infos.remark
                    this.close_dialog()
                }else this.$message.error(res.message)
            })        
        })
    },

    close_dialog(){
        this.edit_infos = {}
        this.gtoken_dialog = false
    },
},  
}

</script>

<style scoped>
.table_list{
  width: 100%;
  margin-top: 20px;
}
.form_regist {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.success{
    height: 30px;
    width: 50px;
    background-color: green;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.proccess{
    height: 30px;
    width: 50px;
    background-color: rgb(148, 95, 248);
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
.fail{
    height: 30px;
    width: 50px;
    background-color: red;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    color: white;
}
</style>



