import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import {Loading} from 'element-ui';
import md5 from 'js-md5';
import globalJs from './assets/js/global.js'
import * as echarts from 'echarts'
//导入全局样式表d
Vue.prototype.$echarts = echarts
import './assets/css/global.css'
import clipboard from 'clipboard';

Vue.prototype.clipboard = clipboard;
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.prototype.$globalJs = globalJs

let loading
Vue.prototype.startLoading = (msg) => {
  loading = Loading.service({
    lock: true,//锁定屏幕
    text: msg,
    background: 'rgba(0, 0, 0, 0.7)',
  })
}
Vue.prototype.endLoading = () => {
  loading.close()
}
Vue.prototype.InputRules = {
  user_name: [
      { required: true, message: "请输入 5 到 16位 用户名", trigger: "blur" },
      {
        min: 5,
        max: 16,
        message: "长度在 5 到 16 个字符",
        trigger: "blur",
      },
    ],
  name_ab: [
    { pattern: /^[0-9A-Za-z_]+$/, required: true, message: "缩写由 数字、字母、下划线 组成", trigger: "blur" },
    {
      min: 5,
      max: 16,
      message: "长度在 5 到 16 个字符",
      trigger: "blur",
    },
    
  ],
  name: [
    { required: true, message: "请输入 5 到 15位 名称", trigger: "blur" },
    {
      min: 5,
      max: 16,
      message: "长度在 5 到 16 个字符",
      trigger: "blur",
    },
  ],
  password: [
    { required: true, message: "请输入 6 到 16位 登入密码", trigger: "blur" },
    {
      min: 6,
      max: 16,
      message: "长度在 6 到 16 个字符",
      trigger: "blur",
    },
  ],
  gtoken: [
      { pattern: /^(\d{6})$/,required: true, message: "请输入正确的谷歌验证码", trigger: "blur" },
    ],
  IP: [
      {pattern: /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))|\*)((\/([012]\d|3[012]|\d))?)(,((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))|\*)((\/([012]\d|3[012]|\d))?))*$/,
      message: '请填入正确IP,多个ip请用英文","分割', trigger: "blur"},
      { required: true, message: "请输入IP地址", trigger: "blur" }          
    ],
  amount: [
      {required: true, message: "请输入正确整数金额", trigger: "blur", pattern: /^(\d+?)$/},           
      {max: 12, message: "长度最大 12 位", trigger: "blur",}
    ],
  recharge_min: [
      {required: true, message: "请输入正确整数金额", trigger: "blur", pattern: /^(\d+?)$/},           
      {max: 12, message: "长度最大 12 位", trigger: "blur",}
    ],
  recharge_max: [
      {required: true, message: "请输入正确整数金额", trigger: "blur", pattern: /^(\d+?)$/},           
      {max: 12, message: "长度最大 12 位", trigger: "blur",}
    ],
  wd_min: [
      {required: true, message: "请输入正确整数金额", trigger: "blur", pattern: /^(\d+?)$/},           
      {max: 12, message: "长度最大 12 位", trigger: "blur",}
    ],
  wd_max: [
      {required: true, message: "请输入正确整数金额", trigger: "blur", pattern: /^(\d+?)$/},           
      {max: 12, message: "长度最大 12 位", trigger: "blur",}
    ],
  recharge_per: [
      { required: true, message: "请输入代收手续费", trigger: "blur" },
    ],
  recharge_single: [
      { required: true, message: "请输入代收单笔手续费", trigger: "blur" },
    ],
  wd_per: [
      { required: true, message: "请输入代付手续费", trigger: "blur" },
    ],
  wd_single: [
      { required: true, message: "请输入代付单笔手续费", trigger: "blur" },
    ],
  merchant_no: [
      { required: true, message: "请输入商户号", trigger: "blur" },
    ],
  secret_key: [
      { required: true, message: "请输入商户私钥", trigger: "blur" },
    ],
  plat_secret_key: [
      { required: true, message: "请输入平台公钥", trigger: "blur" },
    ],
  api_add: [
      { required: true, message: "请输入接口地址", trigger: "blur" },
    ],
  channel_order_no: [
      { required: true, message: "请输入渠道订单号", trigger: "blur" },
    ],
  bankcardnumber: [
    { required: true, message: "请输入卡号", trigger: "blur" },
  ],
  bankcardname: [
    { required: true, message: "请输入户名", trigger: "blur" },
  ],
  bankcode: [
    { required: true, message: "请输入银行编码", trigger: "blur" },
  ],
};
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
